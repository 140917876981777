import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";

const useCookieData = () => {
  const data = useStaticQuery(graphql`
    query {
      englishCookieText: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "cookie-banner" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          contentModules {
            ... on ContentfulLink {
              linkText
            }
            ... on ContentfulTitleDescription {
              description {
                description
              }
            }
          }
        }
      }
      spanishCookieText: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "cookie-banner" }
          node_locale: { eq: "es" }
        }
      ) {
        nodes {
          contentModules {
            ... on ContentfulLink {
              linkText
            }
            ... on ContentfulTitleDescription {
              description {
                description
              }
            }
          }
        }
      }
    }
  `);
  return data;
};

export default useCookieData;
