import { css } from "@emotion/core";
import { theme } from "./theme";

export default css`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      max-height: 1000px;
      padding-bottom: 20px;
    }
    to {
      max-height: 0;
    }
  }
  @-moz-keyframes slideUp {
    from {
      max-height: 1000px;
      padding-bottom: 20px;
    }
    to {
      max-height: 0;
    }
  }
  @-webkit-keyframes slideUp {
    from {
      max-height: 1000px;
      padding-bottom: 20px;
    }
    to {
      max-height: 0;
    }
  }
  @-ms-keyframes slideUp {
    from {
      max-height: 1000px;
      padding-bottom: 20px;
    }
    to {
      max-height: 0;
    }
  }
  @-o-keyframes slideUp {
    from {
      max-height: 1000px;
      padding-bottom: 20px;
    }
    to {
      max-height: 0;
    }
  }

  @keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-moz-keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-webkit-keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-ms-keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-o-keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }

  @keyframes dropdown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-moz-keyframes dropdown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-webkit-keyframes dropdown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-ms-keyframes dropdown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-o-keyframes dropdown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  @keyframes animateUp {
      from {
        max-height: 1000px;
      }
      to {
        max-height: 0;
      }
    }
  }
  @-moz-keyframes animateUp {
      from {
        max-height: 1000px;
      }
      to {
        max-height: 0;
      }
    }
  }
  @-webkit-keyframes animateUp {
      from {
        max-height: 1000px;
      }
      to {
        max-height: 0;
      }
    }
  }
  @-ms-keyframes animateUp {
      from {
        max-height: 1000px;
      }
      to {
        max-height: 0;
      }
    }
  }
  @-o-keyframes animateUp {
      from {
        max-height: 1000px;
      }
      to {
        max-height: 0;
      }
    }
  }

  @keyframes animateDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-moz-keyframes animateDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-webkit-keyframes animateDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-ms-keyframes animateDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-o-keyframes animateDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }

  @keyframes spinUp {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @-moz-keyframes spinUp {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-webkit-keyframes spinUp {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-ms-keyframes spinUp {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-o-keyframes spinUp {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }

  @keyframes spinDown {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes spinDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-webkit-keyframes spinDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-ms-keyframes spinDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  @-o-keyframes spinDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
`;
