import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import moment from "moment-timezone";

import { theme } from "../../../utils/global-styles/theme";

import Circle from "../Icons/Circle";

const NotificationsMenu = ({
  display,
  notificationLinks,
  newNotificationCount,
}) => {
  const NotificationsMenuContainer = styled.div`
    display: ${display};
    position: absolute;
    height: 436px;
    width: 330px;
    border-radius: 3px;
    background-color: ${theme.colors.background};
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.17);
    z-index: 6;
    margin-top: 0.625em;
    right: 0;
    font-family: ${theme.fontFaces.headers};
    color: ${theme.colors.main};
    font-weight: normal;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      width: 100vw;
      position: fixed;
    }
  `;

  const NotificationsMenuHeader = styled.div`
    height: 36px;
    width: 100%;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 0.9em;
    font-weight: bold;
    line-height: 0.9em;
    letter-spacing: 0.37px;
    padding: 1em 1em;
  `;

  const NotificationContainer = styled.div`
    width: 100%;
    height: 80px;
    border-bottom: 1px solid ${theme.colors.light};
    padding-top: 0.9em;
    padding-left: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const NotificationLink = styled(Link)`
    max-width: 100%;
    color: ${theme.colors.primary};
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  `;

  const NotificationTimeDate = styled.div`
    width: 100%;
    color: ${theme.colors.main};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.37px;
    line-height: 16px;
    margin-top: 0.5em;
  `;

  const currentDateTime = moment.utc(new Date());
  const currentTimeZone = moment.tz.guess();

  return (
    <NotificationsMenuContainer className="notifications-menu">
      <NotificationsMenuHeader>
        {newNotificationCount > 0
          ? newNotificationCount === 1
            ? `${newNotificationCount} New Notification`
            : `${newNotificationCount} New Notifications`
          : ""}
      </NotificationsMenuHeader>
      {notificationLinks.map((link, index) => {
        return (
          <NotificationContainer key={index} className="primary-link-container">
            <NotificationLink to={`${link.url}/`}>{link.title}</NotificationLink>
            <NotificationTimeDate>
              {link.viewDateTimeStamp === "null" && <Circle />}{" "}
              {Number(currentDateTime.diff(link.datePublished, "hours")) >= 22
                ? moment(link.datePublished)
                    .utcOffset("-0700")
                    .format("MMMM D, YYYY")
                : moment.tz(link.datePublished, currentTimeZone).fromNow()}
            </NotificationTimeDate>
          </NotificationContainer>
        );
      })}
    </NotificationsMenuContainer>
  );
};

NotificationsMenu.propTypes = {
  display: PropTypes.string,
  notificationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      datePublished: PropTypes.string,
      url: PropTypes.string,
      viewDateTimeStamp: PropTypes.string,
    })
  ),
  newNotificationNumber: PropTypes.number,
};

export default NotificationsMenu;
