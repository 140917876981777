import React from "react";

import { theme } from "../../../utils/global-styles/theme";

const Circle = () => {
  return (
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      className="svg-child-circle-fill-secondary"
    >
      <g
        id="Icons-&amp;-Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-84.000000, -238.000000)">
          <g
            id="notification/badge-alone"
            transform="translate(78.000000, 232.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <circle id="Oval" fillRule="evenodd" cx="10" cy="10" r="4"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Circle;
