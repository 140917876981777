import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../hooks/use-sitemetadata";

function SEO({ description, lang, title, image, pathname, isBlog = false }) {
  const data = useSiteMetadata();
  const {
      defaultTitle,
      defaultDescription,
      siteUrl,
      twitterUsername,
      fbAppId,
    } = data.site.siteMetadata,
    imageUrl = image
      ? `https:${image}`
      : `https:${data.allContentfulAsset.nodes[0].file.url}`,
    seo = {
      title: title ? title : defaultTitle,
      lang,
      description: description ? description : defaultDescription,
      url: pathname ? `${siteUrl}${pathname}` : `${siteUrl}`,
      image: imageUrl,
      social: {
        twitter: twitterUsername,
        fbAppId,
      },
    },
    pageType = isBlog ? "article" : "website",
    metaTags = [
      {
        name: `description`,
        content: seo.description,
      },
      {
        property: `og:title`,
        content: seo.title,
      },
      {
        name: `image`,
        content: seo.image,
      },
      {
        property: `og:title`,
        content: seo.title,
      },
      {
        property: `og:description`,
        content: seo.description,
      },
      {
        property: `og:site_name`,
        content: `Acumen Academy`,
      },
      {
        property: `og:url`,
        content: seo.url,
      },
      {
        property: `og:type`,
        content: pageType,
      },
      {
        property: `og:image`,
        content: seo.image,
      },
      {
        property: `fb:app_id`,
        content: seo.social.fbAppId,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:site`,
        content: seo.social.twitter,
      },
      {
        name: `twitter:creator`,
        content: seo.social.twitter,
      },
      {
        name: `twitter:title`,
        content: seo.title,
      },
      {
        name: `twitter:description`,
        content: seo.description,
      },
      {
        name: `twitter:image`,
        content: seo.image,
      },
    ],
    _env = process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
    prod_relic = "/newrelic_prod.js",
    staging_relic = "/newrelic_staging.js";

  return (
    <Helmet title={seo.title}>
      <html lang={lang} />
      <script
        async
        src={_env === "master" ? prod_relic : staging_relic}
        type="text/javascript"
      />
      {metaTags.map((metaTag, index) => (
        <meta key={index} {...metaTag} />
      ))}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isBlog: PropTypes.bool,
};

export default SEO;
