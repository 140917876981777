import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const ThemeFour = ({ iconColorClassName, width, height, styles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3507.000000 2480.000000"
      preserveAspectRatio="xMidYMid meet"
      css={css`
        ${styles || ""}
      `}
      className={iconColorClassName}
    >
      <g
        transform="translate(0.000000,2480.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M17277 21194 c-2 -2 -333 -13 -395 -14 -24 0 -31 -4 -26 -12 6 -10 4
            -10 -7 -2 -8 6 -21 10 -29 9 -8 -1 -55 -5 -105 -9 -49 -3 -110 -8 -135 -11
            -25 -2 -74 -7 -110 -10 -36 -3 -76 -8 -90 -10 -14 -2 -45 -6 -70 -9 -71 -8
            -144 -18 -160 -21 -8 -1 -70 -11 -137 -21 -67 -9 -120 -20 -119 -23 2 -3 -12
            -7 -32 -7 -59 -3 -142 -16 -138 -23 2 -3 -10 -6 -27 -7 -18 0 -47 -4 -65 -7
            -57 -13 -177 -39 -187 -41 -5 -1 -47 -11 -92 -21 -46 -10 -83 -22 -83 -26 0
            -5 -6 -6 -12 -4 -7 3 -62 -9 -123 -25 -60 -17 -118 -33 -128 -35 -31 -7 -186
            -54 -354 -107 -141 -45 -161 -54 -150 -68 10 -13 10 -13 -4 -2 -14 11 -39 5
            -155 -38 -77 -29 -158 -58 -182 -67 -23 -8 -40 -19 -36 -24 3 -5 -1 -6 -8 -4
            -41 16 -1035 -458 -1070 -509 -4 -6 -8 -7 -8 -3 0 5 -13 -1 -29 -12 -16 -12
            -31 -21 -34 -21 -9 0 -112 -65 -115 -73 -2 -4 -8 -5 -13 -1 -5 3 -9 0 -9 -5 0
            -6 -4 -11 -9 -11 -5 0 -45 -23 -88 -50 -43 -28 -85 -53 -93 -57 -8 -4 -17 -9
            -20 -13 -3 -3 -30 -21 -60 -40 -30 -19 -56 -38 -58 -42 -2 -5 -8 -8 -12 -8
            -11 0 -142 -90 -152 -105 -4 -5 -8 -6 -8 -2 0 5 -7 2 -15 -6 -8 -8 -30 -25
            -50 -37 -19 -12 -32 -27 -29 -32 4 -6 3 -8 -3 -5 -5 4 -24 -4 -40 -16 -17 -12
            -42 -31 -57 -42 -58 -42 -121 -90 -132 -100 -6 -5 -27 -21 -47 -35 -20 -14
            -35 -30 -33 -37 3 -7 0 -10 -7 -7 -7 2 -24 -6 -38 -18 -15 -13 -41 -34 -59
            -48 -18 -14 -41 -32 -50 -40 -10 -8 -39 -33 -66 -55 -27 -22 -64 -54 -84 -71
            -19 -17 -45 -39 -57 -49 -12 -10 -20 -22 -17 -27 3 -5 1 -7 -4 -6 -20 6 -227
            -186 -218 -201 4 -6 1 -9 -7 -6 -16 7 -363 -339 -361 -360 1 -8 -2 -12 -7 -9
            -13 8 -100 -86 -92 -99 3 -5 2 -7 -4 -4 -5 3 -28 -15 -51 -41 -23 -26 -55 -63
            -73 -82 -17 -19 -47 -55 -68 -79 -20 -24 -41 -49 -46 -55 -6 -6 -41 -49 -79
            -96 -39 -47 -74 -88 -79 -91 -5 -4 -6 -13 -2 -20 5 -8 5 -11 1 -7 -12 10 -48
            -31 -40 -45 4 -7 4 -9 -1 -5 -11 10 -38 -20 -29 -33 4 -8 3 -9 -4 -5 -6 3 -16
            -2 -21 -13 -6 -11 -17 -25 -24 -33 -7 -7 -30 -38 -52 -68 -22 -30 -44 -58 -50
            -62 -5 -4 -6 -8 -2 -8 5 0 1 -7 -7 -16 -9 -8 -46 -59 -83 -112 -37 -53 -72
            -100 -78 -104 -5 -4 -6 -8 -2 -8 4 0 -1 -10 -13 -22 -11 -12 -20 -26 -20 -31
            0 -4 -6 -15 -12 -22 -30 -33 -38 -45 -38 -53 0 -5 -7 -15 -15 -22 -9 -7 -13
            -20 -10 -29 3 -8 3 -12 -1 -8 -10 10 -56 -61 -48 -75 4 -6 3 -8 -3 -5 -9 5
            -39 -35 -43 -58 -1 -5 -8 -14 -16 -18 -8 -4 -14 -15 -14 -22 0 -8 -4 -15 -10
            -15 -5 0 -10 -4 -10 -10 0 -5 -18 -39 -40 -75 -22 -36 -40 -69 -40 -74 0 -5
            -3 -11 -7 -13 -5 -1 -21 -29 -38 -60 -16 -32 -34 -58 -39 -58 -5 0 -7 -3 -4
            -6 4 -3 -11 -37 -33 -74 -21 -38 -39 -74 -39 -80 0 -5 -5 -10 -11 -10 -5 0 -8
            -4 -5 -9 3 -5 -1 -14 -9 -21 -12 -10 -13 -16 -4 -27 9 -10 9 -12 -2 -8 -10 3
            -25 -18 -48 -68 -19 -39 -61 -127 -93 -195 -32 -68 -56 -128 -52 -134 4 -6 3
            -8 -3 -5 -10 6 -38 -47 -34 -65 0 -5 -3 -8 -9 -8 -5 0 -10 -5 -10 -11 0 -6
            -16 -47 -35 -91 -19 -44 -33 -84 -30 -89 4 -5 2 -9 -4 -9 -5 0 -13 -10 -17
            -22 -3 -12 -13 -37 -22 -57 -9 -20 -16 -38 -17 -41 -1 -3 -5 -14 -10 -25 -4
            -11 -21 -56 -37 -100 -17 -44 -35 -93 -41 -110 -7 -16 -11 -33 -9 -37 1 -5 -3
            -8 -10 -8 -6 0 -9 -2 -6 -6 3 -3 -3 -28 -13 -55 -11 -28 -17 -54 -14 -59 3 -4
            1 -10 -3 -12 -8 -3 -71 -179 -76 -211 -1 -7 -5 -18 -10 -25 -4 -7 -2 -19 5
            -28 10 -12 9 -13 -6 -8 -15 6 -17 3 -13 -19 3 -15 1 -24 -3 -21 -6 3 -19 -37
            -24 -76 0 -3 -4 -11 -8 -17 -5 -7 -5 -17 -2 -23 3 -5 2 -10 -3 -10 -8 0 -24
            -57 -26 -95 -1 -5 -4 -12 -7 -15 -12 -11 -90 -360 -83 -372 4 -8 3 -10 -2 -5
            -14 13 -65 -255 -52 -271 8 -9 7 -11 -6 -6 -9 4 -16 3 -15 -2 2 -7 -9 -93 -16
            -124 0 -3 -4 -27 -9 -53 -4 -27 -9 -56 -11 -65 -1 -10 -6 -39 -10 -67 -4 -27
            -8 -61 -10 -75 -2 -14 -6 -38 -9 -55 -3 -16 -7 -55 -10 -85 -3 -30 -8 -64 -10
            -76 -7 -32 -19 -174 -20 -226 -1 -27 -3 -48 -6 -48 -3 0 -6 -17 -7 -37 -2 -84
            -15 -258 -19 -262 -2 -2 2 -11 9 -19 7 -10 8 -13 1 -9 -8 4 -12 -3 -11 -21 0
            -15 -2 -72 -3 -127 -2 -56 0 -107 5 -115 5 -8 4 -11 -2 -7 -15 9 -5 -637 12
            -793 2 -19 5 -56 6 -83 1 -26 5 -49 8 -51 4 -3 6 -24 4 -47 -1 -24 3 -50 10
            -58 10 -12 9 -13 -2 -7 -10 6 -12 4 -8 -10 3 -11 8 -46 12 -79 3 -33 8 -73 10
            -90 12 -93 17 -133 20 -162 5 -39 14 -101 20 -133 3 -14 7 -43 10 -65 4 -22 8
            -49 10 -60 2 -11 4 -28 4 -38 1 -12 5 -16 14 -11 9 6 9 5 0 -8 -7 -8 -11 -17
            -9 -19 5 -5 14 -44 22 -104 4 -22 10 -45 15 -51 5 -6 6 -13 3 -16 -7 -7 37
            -218 45 -214 3 2 6 -8 7 -22 2 -61 77 -366 95 -389 9 -10 8 -13 -1 -13 -10 0
            -10 -3 0 -16 8 -8 15 -29 17 -47 2 -17 10 -42 19 -55 12 -18 12 -22 3 -16 -12
            6 -12 5 0 -9 7 -9 11 -17 7 -17 -3 0 1 -17 8 -37 15 -38 75 -225 81 -253 2 -8
            23 -67 45 -130 23 -63 44 -125 47 -137 3 -13 10 -20 16 -16 6 3 7 1 3 -5 -9
            -14 68 -215 79 -205 4 5 5 3 1 -4 -3 -6 4 -36 18 -65 25 -56 40 -101 37 -107
            -2 -2 2 -7 8 -11 6 -3 13 -16 15 -27 3 -11 9 -29 14 -39 6 -11 19 -38 30 -61
            10 -23 23 -39 27 -35 5 4 5 2 1 -5 -6 -11 135 -320 152 -333 4 -3 8 -13 10
            -22 1 -9 23 -54 48 -99 25 -46 62 -113 81 -151 20 -41 39 -66 45 -62 7 4 8 2
            4 -4 -9 -15 34 -93 47 -85 6 4 7 1 3 -6 -5 -7 3 -29 18 -51 14 -22 26 -42 26
            -45 0 -4 17 -32 38 -63 21 -31 42 -65 45 -75 4 -10 11 -15 17 -12 5 3 7 0 5
            -8 -3 -7 8 -32 25 -55 16 -23 30 -44 30 -47 0 -9 44 -69 53 -73 4 -2 5 -7 2
            -11 -2 -5 7 -19 20 -31 14 -13 25 -28 25 -32 0 -5 7 -16 15 -25 8 -8 13 -19
            10 -24 -4 -5 -2 -9 3 -9 5 0 27 -27 49 -60 22 -32 44 -56 48 -52 5 4 5 2 2 -4
            -6 -11 42 -81 66 -96 7 -4 8 -8 4 -8 -5 0 5 -18 22 -39 17 -21 31 -44 31 -51
            0 -7 6 -10 13 -7 8 3 14 -1 14 -7 0 -13 159 -227 206 -278 13 -14 34 -40 46
            -57 13 -17 27 -28 33 -25 7 4 8 2 4 -4 -3 -6 13 -32 36 -59 117 -131 102 -114
            193 -218 97 -111 161 -175 170 -168 4 5 5 3 2 -3 -4 -7 11 -28 33 -48 22 -20
            39 -36 37 -36 -6 0 132 -137 253 -250 68 -63 127 -114 131 -113 5 2 8 -1 6 -6
            -1 -5 9 -19 23 -31 14 -12 52 -45 85 -75 33 -29 76 -65 95 -81 19 -16 40 -35
            46 -43 7 -8 16 -11 24 -6 8 5 11 4 7 -2 -6 -10 -3 -13 78 -78 28 -22 52 -43
            53 -48 2 -4 8 -5 13 -1 5 3 9 1 9 -5 0 -8 25 -29 115 -99 6 -4 46 -34 90 -66
            44 -32 96 -71 115 -86 74 -57 180 -130 189 -130 5 0 11 -4 13 -8 5 -13 261
            -175 275 -175 7 0 11 -3 8 -5 -6 -6 60 -52 74 -52 6 0 11 -4 11 -10 0 -5 5
            -10 10 -10 6 0 28 -11 50 -25 22 -14 40 -22 40 -18 0 5 4 3 8 -3 8 -13 137
            -90 257 -154 44 -23 95 -51 112 -62 18 -11 38 -16 45 -12 7 4 10 3 5 -1 -8 -9
            214 -126 225 -118 5 2 8 0 8 -5 0 -13 70 -43 83 -36 7 4 9 3 4 -1 -6 -7 53
            -43 83 -50 19 -5 179 -77 214 -96 10 -6 16 -6 12 -1 -3 5 25 -6 62 -23 103
            -50 173 -75 186 -68 6 3 8 2 3 -2 -6 -7 28 -24 78 -39 6 -2 18 -6 27 -10 9 -4
            22 -9 30 -10 7 -1 23 -8 36 -15 13 -7 30 -10 38 -7 8 3 13 2 10 -3 -6 -10 359
            -134 399 -136 6 0 12 -3 15 -6 7 -8 311 -94 360 -102 14 -2 32 -7 40 -10 8 -4
            26 -9 40 -11 39 -8 61 -13 110 -26 25 -7 54 -14 65 -16 11 -2 31 -6 45 -10 14
            -3 39 -8 55 -11 17 -3 39 -10 49 -15 16 -9 18 -8 12 8 -5 17 -5 17 7 3 7 -9
            33 -20 57 -24 25 -4 54 -9 65 -11 11 -3 34 -6 51 -8 17 -2 35 -5 40 -8 8 -5
            94 -19 144 -23 8 -1 26 -5 40 -8 39 -10 110 -19 167 -21 28 0 49 -4 46 -9 -3
            -5 63 -11 80 -6 1 0 14 -4 30 -10 18 -7 27 -7 27 0 0 6 8 6 21 -2 12 -6 32 -9
            45 -6 13 2 24 1 24 -2 0 -4 23 -7 50 -7 28 1 50 -2 50 -5 0 -3 17 -6 38 -6 50
            0 185 -14 195 -20 4 -2 7 1 7 7 0 7 6 8 18 1 9 -5 42 -8 72 -7 30 1 65 -2 78
            -6 15 -5 22 -4 22 5 0 7 3 8 8 2 9 -12 32 -13 51 -1 9 6 12 5 8 -1 -7 -10 34
            -12 315 -14 91 -1 172 2 179 6 8 5 10 4 5 -4 -6 -8 -1 -10 16 -6 12 3 80 7
            150 8 69 1 125 4 123 7 -2 3 24 3 58 2 58 -4 60 -3 47 15 -14 17 -14 17 4 3
            17 -14 96 -22 96 -10 0 3 29 5 65 5 36 0 65 3 65 7 0 3 10 4 22 0 16 -4 19 -2
            13 9 -6 11 -5 12 6 1 16 -15 33 -16 23 -1 -5 8 -1 8 13 0 11 -6 23 -8 25 -5 5
            5 112 17 161 18 15 1 27 3 27 6 0 3 12 5 28 6 15 0 52 4 82 8 30 5 65 9 78 9
            12 1 22 3 22 7 0 3 12 5 28 6 44 1 135 13 170 23 18 6 35 10 37 10 28 -1 114
            15 111 20 -2 3 10 6 27 7 65 2 78 4 73 12 -2 4 12 6 32 4 35 -5 36 -4 22 14
            -13 17 -13 18 1 7 16 -13 79 -13 79 -1 0 5 3 7 8 7 4 -1 9 -1 12 0 3 1 19 4
            35 6 44 6 59 10 73 18 6 5 12 6 12 4 0 -7 60 7 235 53 88 23 170 44 183 47 16
            4 21 11 17 21 -4 11 -2 12 7 5 15 -12 81 -5 73 7 -3 4 1 8 8 7 30 -1 48 4 43
            11 -3 5 8 6 25 4 16 -2 29 0 29 5 0 5 8 9 18 10 23 2 344 108 391 130 18 8 41
            17 50 18 9 2 18 4 21 5 3 1 8 2 13 1 4 0 7 4 7 10 0 5 4 8 9 5 5 -3 21 0 37 8
            16 7 57 24 92 37 34 13 62 27 62 31 0 4 7 4 17 1 12 -5 14 -3 8 7 -5 8 -4 11
            3 6 14 -8 223 79 230 97 2 4 8 5 13 1 12 -7 51 21 42 30 -4 4 0 4 9 1 9 -4 39
            5 67 19 28 15 51 23 51 19 0 -5 4 -3 8 2 10 14 47 42 40 30 -4 -5 72 28 167
            75 96 47 172 90 170 96 -2 7 4 10 16 7 11 -3 19 0 19 6 0 7 5 9 10 6 6 -3 10
            -2 10 3 0 6 12 13 27 17 14 3 38 15 51 26 14 10 31 19 38 19 8 0 14 5 14 11 0
            6 7 8 17 5 10 -4 14 -4 10 0 -6 6 33 31 110 70 18 9 31 20 28 24 -3 4 3 7 13
            6 9 0 16 5 14 12 -1 8 1 11 6 8 10 -6 206 107 217 124 4 6 11 8 16 5 5 -4 9
            -2 9 3 0 5 15 17 34 27 19 11 32 23 30 27 -3 5 -2 7 3 6 13 -3 53 13 53 21 0
            5 14 14 30 21 17 7 30 16 30 21 0 4 9 11 20 14 11 4 17 11 14 17 -4 6 -2 8 3
            5 10 -6 59 25 75 48 5 6 8 6 8 0 0 -5 6 -3 13 6 14 19 58 44 70 41 5 -1 6 2 3
            7 -3 5 1 12 10 15 8 3 12 11 8 17 -4 7 -3 9 4 6 14 -9 73 25 68 39 -3 6 3 11
            12 10 9 0 16 5 14 12 -1 8 2 11 8 7 6 -3 17 0 25 7 34 31 43 36 49 31 3 -4 6
            -1 6 5 0 6 20 25 45 42 26 18 43 36 39 42 -4 7 -2 8 5 4 13 -9 43 18 33 29 -4
            5 -2 5 4 2 6 -4 22 3 35 14 13 11 34 29 47 40 12 10 22 17 22 15 0 -3 16 11
            36 30 20 19 41 34 46 34 6 0 8 6 5 14 -3 8 1 13 11 12 9 0 16 5 14 12 -1 8 2
            11 6 8 12 -7 58 33 54 46 -1 5 2 7 6 4 12 -7 63 40 54 49 -4 4 -2 5 4 3 15 -4
            134 103 127 115 -3 6 -1 7 5 3 7 -4 39 20 77 58 36 35 65 62 65 58 0 -3 11 8
            23 26 13 17 26 30 28 29 2 -2 68 61 146 140 78 79 140 143 138 143 -2 0 16 20
            40 44 25 24 42 46 38 50 -3 3 1 6 10 6 10 0 17 4 17 10 0 5 18 26 40 46 22 21
            39 39 37 40 -2 2 21 29 50 60 29 31 50 61 46 66 -3 6 -1 8 5 4 6 -3 23 10 39
            31 15 21 30 40 33 43 8 8 63 73 98 118 19 22 41 50 50 61 10 11 26 32 37 47
            11 15 25 32 30 38 6 6 24 29 40 51 17 22 34 43 40 47 5 4 7 8 3 8 -5 0 1 9 12
            20 12 12 18 26 14 32 -4 6 -3 8 4 5 5 -4 23 13 39 38 15 25 32 45 36 45 5 0 6
            4 3 9 -3 5 1 12 10 15 9 4 14 13 10 22 -3 8 -2 13 3 10 12 -8 73 82 65 96 -4
            6 -3 8 3 5 14 -9 46 40 37 55 -4 6 -3 8 3 5 14 -9 86 99 77 115 -4 6 -3 8 3 5
            5 -3 17 3 26 14 9 10 14 19 10 19 -3 0 1 8 9 18 22 25 118 182 118 193 0 5 4
            9 8 9 13 0 33 50 26 63 -4 7 -4 9 1 5 10 -10 38 19 30 32 -3 5 -2 10 3 10 17
            0 301 520 285 520 -5 0 -1 5 7 10 8 5 15 13 15 17 0 4 27 62 59 128 32 66 58
            128 59 137 1 9 5 14 9 12 4 -3 8 0 8 6 0 6 10 32 21 58 48 104 60 136 55 149
            -3 7 -1 12 5 10 11 -2 60 109 51 118 -3 3 0 5 6 5 7 0 12 6 12 13 0 7 15 51
            34 98 19 46 36 93 38 104 1 11 6 22 9 25 14 12 100 280 93 291 -4 7 -3 9 3 6
            9 -6 24 37 84 238 16 55 37 124 46 154 9 30 16 67 17 82 1 15 4 25 7 24 8 -6
            50 185 43 197 -4 6 -2 8 3 5 15 -9 23 39 9 54 -8 9 -8 10 2 5 6 -4 12 -4 13 1
            0 4 2 15 4 23 2 8 7 28 10 45 3 16 13 61 21 100 23 106 23 106 34 165 6 30 12
            66 15 80 3 14 12 68 20 120 9 52 18 106 20 120 3 14 8 45 10 70 3 25 8 56 10
            70 3 14 7 50 11 80 3 30 8 57 10 59 2 2 -2 11 -9 19 -9 13 -9 14 1 8 8 -5 12
            2 13 26 1 18 3 42 4 53 8 73 17 159 21 210 3 33 7 61 9 63 2 2 -1 17 -5 33 -5
            18 -5 27 1 24 5 -4 9 19 10 52 0 32 2 90 5 128 10 184 10 225 0 236 -8 9 -8
            10 1 5 10 -5 12 67 8 341 -3 191 -7 364 -9 383 -2 19 -7 80 -11 135 -3 55 -8
            114 -9 130 -2 17 -7 66 -10 109 -4 43 -9 87 -11 97 -2 10 -6 44 -10 76 -3 32
            -8 69 -10 83 -2 14 -6 48 -10 75 -11 88 -25 180 -40 262 -3 16 -8 46 -11 68
            -6 39 -70 360 -80 400 -3 11 -7 31 -10 45 -7 38 -75 313 -81 330 -3 8 -7 20
            -8 25 -2 6 -8 30 -16 55 -7 25 -13 50 -14 55 -4 18 -85 280 -92 295 -3 8 -7
            20 -9 27 -6 31 -28 89 -37 101 -5 6 -7 12 -4 12 6 0 -55 177 -72 208 -6 11 -7
            22 -4 26 3 3 1 6 -5 6 -7 0 -11 3 -10 8 3 7 -3 23 -54 152 -170 425 -447 985
            -634 1278 -19 29 -34 57 -34 62 0 6 -4 10 -10 10 -5 0 -10 4 -10 10 0 17 -222
            350 -233 350 -6 0 -8 3 -4 6 6 7 -101 160 -241 344 -93 122 -250 320 -262 330
            -3 3 -48 55 -100 115 -52 61 -113 130 -136 155 -23 25 -56 61 -74 80 -117 128
            -432 438 -575 565 -190 167 -232 204 -386 326 -251 201 -755 559 -788 559 -5
            0 -11 4 -13 8 -3 8 -235 150 -358 220 -401 226 -889 452 -1305 604 -123 45
            -489 166 -520 173 -11 2 -20 4 -20 5 0 1 -9 4 -20 6 -26 6 -29 7 -100 28 -33
            10 -67 20 -75 21 -8 2 -77 20 -153 39 -77 20 -149 38 -160 40 -12 2 -44 10
            -72 16 -27 6 -58 13 -68 15 -10 2 -26 6 -35 9 -9 3 -34 7 -54 10 -21 3 -57 10
            -80 15 -24 5 -59 12 -78 15 -19 3 -46 8 -60 11 -14 3 -41 8 -60 11 -19 2 -46
            7 -60 10 -48 9 -138 22 -270 39 -25 4 -56 8 -70 10 -33 5 -136 16 -277 30 -58
            6 -177 15 -248 20 -120 8 -693 23 -698 19z m2059 -3496 c10 -5 50 -10 101 -12
            18 0 33 -5 33 -10 0 -4 11 -6 25 -4 14 3 25 2 25 -1 0 -4 10 -8 23 -9 31 -3
            44 -6 111 -26 32 -10 64 -19 70 -20 6 -1 21 -8 34 -14 12 -7 28 -10 34 -6 7 4
            8 3 4 -4 -4 -7 -1 -12 8 -12 8 0 52 -19 98 -42 46 -22 89 -41 96 -41 6 0 10
            -3 7 -5 -8 -8 128 -91 138 -85 6 3 7 1 3 -5 -4 -7 -3 -12 3 -12 5 0 26 -15 45
            -34 20 -18 36 -29 36 -25 0 5 16 -9 36 -31 20 -22 41 -37 48 -33 6 3 8 3 4 -1
            -3 -4 14 -29 39 -54 25 -26 68 -74 96 -107 28 -33 53 -59 57 -58 4 1 7 -3 7
            -10 0 -7 18 -39 41 -72 22 -33 59 -96 83 -140 24 -44 45 -82 49 -85 3 -3 5
            -12 5 -20 0 -8 5 -21 12 -28 7 -8 11 -17 8 -22 -3 -5 -1 -11 5 -15 5 -3 10
            -15 10 -26 0 -10 4 -19 10 -19 5 0 6 -7 3 -17 -5 -12 -3 -14 7 -8 7 5 11 4 7
            -1 -3 -5 6 -49 19 -98 13 -50 25 -107 28 -127 2 -21 6 -42 9 -46 8 -13 19
            -181 18 -278 -2 -177 -27 -336 -78 -485 -8 -25 -16 -49 -18 -55 -9 -35 -35
            -94 -85 -192 -56 -109 -57 -127 -6 -84 11 9 16 11 13 4 -5 -8 -1 -8 18 0 16 8
            23 17 19 27 -4 11 -2 12 7 3 12 -12 68 1 80 19 3 6 9 9 13 9 24 -3 46 0 43 7
            -3 4 9 8 26 9 18 1 61 9 97 17 77 18 134 26 211 30 31 2 62 7 69 11 7 5 11 4
            7 -1 -7 -12 146 -11 155 1 5 6 8 6 8 -2 0 -6 8 -10 17 -9 9 2 34 0 54 -4 27
            -5 36 -4 32 4 -5 8 -3 8 8 0 8 -7 34 -15 59 -19 75 -12 82 -14 159 -34 41 -11
            79 -17 85 -14 5 3 6 1 2 -5 -4 -7 -1 -12 9 -12 9 0 30 -7 48 -17 31 -16 32
            -16 17 3 -12 16 -10 16 10 -2 21 -18 41 -29 70 -39 3 -1 22 -9 42 -19 21 -9
            41 -16 44 -15 4 0 10 -3 13 -9 3 -5 29 -22 56 -37 28 -16 52 -32 55 -36 11
            -14 68 -38 75 -31 5 4 5 2 1 -5 -4 -7 5 -19 21 -30 30 -19 50 -36 145 -123 34
            -30 65 -54 70 -52 5 1 7 -2 3 -8 -4 -6 12 -30 35 -54 23 -24 52 -60 66 -80 14
            -21 30 -34 36 -30 7 4 8 2 4 -5 -4 -6 4 -25 18 -41 13 -17 28 -39 31 -50 4
            -11 11 -17 17 -14 6 4 9 3 6 -2 -3 -5 3 -22 13 -39 10 -16 26 -47 35 -67 9
            -21 21 -35 27 -31 6 3 7 1 3 -5 -4 -6 0 -25 9 -42 8 -16 16 -33 18 -37 1 -5 4
            -7 7 -6 3 1 6 -6 7 -15 0 -9 17 -69 37 -132 19 -63 33 -116 31 -118 -2 -2 1
            -15 6 -29 5 -14 12 -42 15 -62 3 -20 8 -47 10 -61 3 -14 7 -98 9 -187 2 -89 6
            -167 11 -174 4 -7 3 -9 -3 -6 -6 4 -13 -16 -16 -51 -7 -60 -12 -91 -28 -160
            -5 -24 -6 -48 -3 -54 4 -6 3 -8 -3 -5 -7 4 -14 -9 -19 -31 -9 -43 -8 -41 -18
            -64 -5 -10 -9 -29 -10 -42 0 -13 -3 -22 -5 -20 -8 8 -31 -58 -24 -69 3 -6 1
            -7 -5 -3 -11 7 -16 -8 -15 -42 1 -7 -1 -11 -4 -8 -10 9 -64 -104 -57 -117 4
            -6 4 -9 -1 -5 -11 11 -38 -29 -29 -44 4 -7 3 -8 -5 -4 -6 4 -14 0 -18 -11 -4
            -10 -9 -20 -13 -23 -3 -3 -14 -18 -24 -35 -23 -35 -27 -41 -61 -79 -13 -16
            -22 -34 -18 -40 3 -6 3 -8 -2 -4 -11 10 -46 -32 -38 -45 3 -5 2 -7 -3 -4 -13
            8 -77 -59 -69 -72 3 -6 0 -9 -7 -6 -7 3 -36 -17 -64 -43 -28 -26 -63 -56 -79
            -67 -15 -11 -33 -26 -40 -32 -8 -7 -16 -13 -18 -13 -2 0 -33 -19 -68 -43 -35
            -23 -86 -53 -114 -67 -27 -14 -53 -29 -56 -33 -3 -5 -9 -8 -13 -7 -3 0 -27 -8
            -52 -20 -26 -11 -64 -25 -85 -31 -21 -7 -36 -16 -32 -22 3 -6 2 -7 -4 -4 -5 3
            -34 -1 -64 -9 -30 -8 -58 -16 -64 -18 -148 -40 -396 -58 -570 -41 -69 7 -86
            10 -135 20 -14 3 -34 7 -45 9 -17 4 -80 19 -125 30 -45 12 -191 71 -261 106
            -87 44 -260 152 -279 175 -7 8 -17 12 -24 8 -6 -3 -8 -3 -4 2 8 8 -42 52 -54
            48 -5 -2 -7 0 -6 5 1 4 -34 44 -78 88 -88 90 -88 91 -160 189 -65 89 -131 207
            -176 315 -42 100 -50 121 -53 135 -2 6 -10 36 -19 67 -10 31 -19 70 -22 85 -3
            15 -11 34 -17 42 -10 12 -9 13 2 6 11 -6 13 -4 7 11 -14 34 -28 172 -30 284
            -1 41 -5 81 -10 89 -6 10 -6 12 2 7 12 -7 13 -3 16 116 0 31 4 54 9 51 4 -2 7
            8 8 24 0 15 2 35 4 43 10 36 19 87 19 107 1 11 4 19 9 16 7 -4 9 8 10 54 1 12
            3 20 6 17 5 -5 52 111 53 134 1 6 5 12 10 12 4 0 8 5 8 11 0 15 53 123 63 128
            5 1 0 13 -10 25 -9 11 -11 15 -3 9 20 -18 25 -16 38 12 l12 25 -38 -16 c-110
            -48 -255 -97 -324 -108 -18 -3 -44 -8 -58 -11 -73 -15 -141 -20 -290 -20 -192
            0 -259 7 -391 40 -431 109 -772 356 -1011 731 -25 38 -49 80 -52 92 -4 12 -12
            22 -17 22 -5 0 -8 3 -7 8 1 4 -9 30 -21 57 -25 55 -71 171 -77 195 -2 8 -11
            42 -20 75 -18 68 -22 85 -30 135 -3 19 -8 46 -11 60 -2 14 -7 99 -9 190 -2 91
            -6 171 -9 178 -3 7 0 10 5 7 6 -4 10 10 11 32 1 40 1 42 20 143 16 78 16 79
            19 83 1 1 3 10 6 20 2 9 6 25 8 35 3 9 5 18 6 20 2 1 4 7 5 12 1 6 3 11 5 13
            1 1 3 9 5 17 1 8 13 40 25 70 36 89 41 105 40 109 -1 2 3 7 10 11 6 4 12 16
            13 28 0 11 4 19 7 17 4 -2 13 11 20 30 8 19 17 35 22 35 4 0 8 5 8 11 0 15
            114 179 150 218 17 17 30 35 30 40 0 5 7 12 16 15 8 3 12 11 9 16 -4 6 -2 9 3
            8 5 -2 30 17 55 42 68 67 85 82 162 140 39 29 69 57 67 62 -1 5 2 7 8 3 5 -3
            22 2 38 13 15 11 51 31 80 46 29 15 51 30 48 35 -3 4 5 7 17 7 12 -1 21 4 20
            9 -1 6 10 11 24 12 14 0 29 6 33 13 4 6 9 10 11 8 8 -6 88 25 83 33 -3 5 1 6
            9 4 7 -3 30 1 50 10 21 9 42 13 47 10 6 -4 10 -2 10 3 0 5 6 9 13 10 6 0 23 3
            37 7 14 3 42 9 62 12 21 3 36 8 34 12 -3 3 14 5 36 3 22 -3 38 0 35 5 -3 4 11
            7 31 5 21 -1 57 1 80 5 45 8 253 9 268 1z"
        />
      </g>
    </svg>
  );
};

ThemeFour.propTypes = {
  iconColorClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.string,
};

export default ThemeFour;
