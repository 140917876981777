import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../../../utils/global-styles/theme";

const StyledTextInput = styled("input")`
    background-color: transparent;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${props => props.placeholderColor};
    }
  `,
  InputWrap = styled("div")`
    border: 1px solid #fff;
  `,
  StyledErrorText = styled("p")`
    margin-bottom: 15px;
    font-size: 17px;
    font-family: ${theme.fontFaces.help};
  `;

const InputTextField = ({
  name,
  placeholder,
  required,
  handleChange,
  handleValidation,
  label,
  styles,
  value,
  type,
  errorFromParent,
  helpText,
  className = "",
  title = "footer",
  dataTestId = null,
  inputId = null,
  placeholderColor = null,
}) => {
  const [error, setError] = useState(false),
    hasErrors = error || errorFromParent,
    idPrefix = title.split(" ").join("-").toLowerCase();
  return (
    <>
      <InputWrap
        id={inputId}
        className={
          hasErrors
            ? `${className} dynamic-form-input-wrapper has-errors`
            : `${className} dynamic-form-input-wrapper`
        }
        css={
          styles.wrapperStyles
            ? css`
                ${styles.wrapperStyles.styles};
                margin: ${hasErrors
                  ? "15px 0 0 0"
                  : hasErrors === false
                  ? "15px 0"
                  : "0"};
                ${styles.wrapperStyles.styles}
              `
            : `margin: ${
                hasErrors ? "15px 0 0 0" : hasErrors === false ? "15px 0" : "0"
              };
            ${styles.wrapperStyles.styles}`
        }
      >
        <label
          css={
            styles.labelStyles
              ? css`
                  ${styles.labelStyles.styles}
                `
              : ""
          }
          className={"main"}
          for={idPrefix + "-" + name}
        >
          {label}
        </label>
        <StyledTextInput
          className={className}
          id={idPrefix + "-" + name}
          data-testId={dataTestId}
          type={type}
          placeholder={placeholder}
          required={required}
          name={name}
          onChange={ handleChange ? e => handleChange(e, setError) : () => null}
          onBlur={
            handleValidation
              ? e => {
                  handleValidation(e, setError);
                }
              : () => null
          }
          value={value}
          css={
            styles.valueStyles
              ? css`
                  ${styles.valueStyles.styles}
                `
              : ""
          }
          data-testId={dataTestId}
          placeholderColor={placeholderColor}
        />
      </InputWrap>
      {helpText && !error && !errorFromParent && (
        <StyledErrorText>{helpText}</StyledErrorText>
      )}
      {error?.length > 0 && <StyledErrorText>{error}</StyledErrorText>}
      {errorFromParent?.length > 0 && <StyledErrorText>{errorFromParent}</StyledErrorText>}
    </>
  );
};

InputTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  styles: PropTypes.shape({
    valueStyles: PropTypes.object,
    wrapperStyles: PropTypes.object,
    labelStyles: PropTypes.object,
    helpTextStyles : PropTypes.object,
  }),
  value: PropTypes.string,
  errorFromParent: PropTypes.string,
  dataTestId: PropTypes.string,
  inputId: PropTypes.string,
};

export default InputTextField;