import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

const SearchIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      version="1.1"
      css={css`
        margin-top: 0.2em;
      `}
      className={className}
    >
      <g
        id="Icons-&amp;-Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-178.000000, -236.000000)">
          <g id="Icon/Search" transform="translate(174.000000, 232.000000)">
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
              d="M12.7990616,11.7386877 L12.2429887,11.7386877 L12.0458996,11.5487489 C12.8905673,10.563881 13.3269789,9.22023986 13.0876564,7.79218143 C12.7568282,5.83651521 11.1238041,4.27479614 9.15291295,4.03561394 C6.17545952,3.66980587 3.66961219,6.1741842 4.03563483,9.14989215 C4.27495733,11.1196279 5.83759246,12.7516947 7.79440583,13.0823289 C9.22330192,13.3215111 10.5677312,12.8853553 11.5531768,12.0411829 L11.743227,12.2381564 L11.743227,12.7939033 L14.7347583,15.7836808 C15.023353,16.0721064 15.4949591,16.0721064 15.7835539,15.7836808 C16.0721487,15.4952552 16.0721487,15.0239256 15.7835539,14.7355 L12.7990616,11.7386877 Z M8.5757234,11.7386877 C6.82303804,11.7386877 5.40821975,10.3246988 5.40821975,8.57304096 C5.40821975,6.82138309 6.82303804,5.40739421 8.5757234,5.40739421 C10.3284087,5.40739421 11.743227,6.82138309 11.743227,8.57304096 C11.743227,10.3246988 10.3284087,11.7386877 8.5757234,11.7386877 Z"
              id="Search"
              fill={theme.colors.dkGray}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SearchIcon.propTypes = {
  className: PropTypes.string,
};

export default SearchIcon;
