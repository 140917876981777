import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const ThemeTwo = ({ iconColorClassName, width, height, styles }) => {
  return (
    <svg
      width={width || "20px"}
      height={height || "20px"}
      className={iconColorClassName}
      css={css`
        ${styles || ""}
      `}
      viewBox="0 0 133.88 170"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M85.32,150.23A19.78,19.78,0,1,0,65.54,170,19.78,19.78,0,0,0,85.32,150.23Z"
          />
          <path
            className="cls-1"
            d="M133.88,113.07a31.64,31.64,0,1,0-31.64,31.63A31.65,31.65,0,0,0,133.88,113.07Z"
          />
          <path
            className="cls-1"
            d="M101.23,50.61a50.62,50.62,0,1,0-50.62,50.62A50.61,50.61,0,0,0,101.23,50.61Z"
          />
        </g>
      </g>
    </svg>
  );
};

ThemeTwo.propTypes = {
  iconColorClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.string,
};

export default ThemeTwo;
