import { theme } from "./theme";
import typography from "./_typography";
import buttons from "./_buttons";
import animations from "./_animations";
import { css } from "@emotion/core";
import values from "lodash/values";

const {
  reset,
  fontImport,
  colors: {
    white,
    light,
    main,
    dkGray,
    accent,
    primary,
    secondary,
    dkBlue,
    black,
    tertiary,
    background,
    royalBlue,
  },
} = theme;

const globalStylesWrapper = css`
  ${values(fontImport).join("\n")}
  ${reset}
  ${typography}
  ${buttons}
  ${animations}

  .show-bg-image.dark-text.whitened {
    color: ${main};

    &:hover {
      color: white;

      a {
        color: white;
        border-bottom-color: white;
      }
    }

    a {
      color: ${main};
      border-bottom-color: ${main};
    }
  }
  

  .show-bg-image.white-text.whitened {
    color: white !important;

    a {
      color: white !important;
      border-bottom-color: white !important;
    }
  }

  .hide-bg-image.dark-text.whitened {
    color: white;

    a {
      color: white;
      border-bottom-color: white;
    }
  }

  .hide-bg-image.white-text.whitened {
    color: white;

    a {
      color: white;
      border-bottom-color: white;
    }
  }

  // BEGINNING OF MOBIELE NAV ANIMATIONS
  .drop_container {
    display: none;
    animation: animateFromBottom .3s;
  }
  
  .drop_container.show {
    display: block;
  }
  
  .drop_container.show.animate-out {
    animation: animateToBottom .3s;
  }
  
  .drop_container>.item {
    display: flex;
  }
  
  @keyframes animateFromBottom {
    from {
      transform: translate3d(0, -100px, 0);
      opacity: 0
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1
    }
  }
  
  @keyframes animateToBottom {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1
    }
    to {
      transform: translate3d(0, -100px, 0);
      opacity: 0
    }
  }

  // END OF MOBIELE NAV ANIMATIONS

  .hide-bg-image.white-text.not-whitened {
      color: ${main};

      a {
        color: ${main};
        border-bottom-color: ${main};
      }
  }

  .hide-bg-image.dark-text.not-whitened {
    color: ${main};

      a {
        color: ${main};
        border-bottom-color: ${main};
      }
  }

  

  .primary {
    color: ${primary};
  }
  .secondary {
    color: ${secondary};
  }
  .white {
    color: ${white};
  }
  .main {
    color: ${main};
  }
  .main-fill {
    fill: ${main};
  }
  .dark-gray-text {
    color: ${dkGray};
  }
  .disabled-text {
    color: ${accent};
  }
  .bold {
    font-weight: bold;
  }
  .primary-bg {
    background-color: ${primary};
  }
  .main-bg {
    background-color: ${background};
  }
  .dark-blue-bg {
    background-color: ${dkBlue};
  }
  .dark-blue-text {
    color: ${dkBlue};
  }
  .border-bottom-dark-blue {
    border-bottom: 2px solid ${dkBlue};
  }
  .border-bottom-light {
    border-bottom: 2px solid ${background};
  }

  .dark-blue-bottom-border {
    border-bottom: 1px solid ${dkBlue};
  }
  .secondary-bg {
    background-color: ${secondary};
  }
  .pale-white-bg {
    background-color: ${background};
  }
  .email-input {
    color: ${secondary};
    border: none;
    text-align: left;
    border-bottom: 1px solid white;

    &::placeholder {
      color: ${secondary};
    }
  }
  .underline-effect {
    position: relative;
    display: inline-block;
    text-decoration: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 23px;
      height: 1px;
      width: 0;
      background-color: black;
      transition: width 0.2s ease-out;
    }

    &::before {
      left: 50%;
      transform: translateX(-100%);
    }

    &::after {
      right: 50%;
      transform: translateX(100%);
    }

    &:hover::before,
    &:hover::after {
      width: 50%;
    }
  }
  .grey-bg {
    background-color: #f2f2f2;
  }
  .white-bg {
    background-color: ${white};
  }
  .accent-bg {
    background-color: ${accent};
  }
  .light-bg {
    background-color: ${light};
  }
  .royalblue-bg {
    background-color: ${royalBlue};
  }
  .primary-link-container a {
    color: ${primary};
    &:hover {
      color: ${secondary};
    }
  }
  .main-link-container a {
    color: ${main};
    &:hover {
      color: ${primary};
    }
  }
  .primary-hover {
    &:hover {
      color: ${primary};
    }
  }
  .primary-placeholder {
    ::placeholder {
      color: ${primary};
    }
  }
  .black-placeholder {
    ::placeholder {
      color: ${black};
    }
  }
  .link-container-secondary-hover a {
    &:hover {
      color: ${secondary};
      text-decoration-color: ${secondary};
    }
  }
  .white-hover:hover {
    color : ${white};
  }
  .secondary-hover:hover {
    color: ${secondary};
    transition: 0.2s ease-in-out;
  }
  .jungle-green-hover:hover {
    color: ${theme.colors.jungleGreen};
    transition: 0.2s ease-in-out;
  }
  .jungle-green {
    color: ${theme.colors.jungleGreen};
  }
  .secondary-background-hover:hover, .secondary-background-hover:focus {
    transition: 0.2s ease-in-out;
    background-color: ${secondary};
    h2, p {
      color: ${black};
    }
  }
  .primary-placeholder {
    color: ${theme.colors.dkGray};
    &::placeholder {
      color: ${primary};
      font-weight: bold;
      font-family: ${theme.fontFaces.headers};
    }
  }

  .header-links-font {
    font-family: ${theme.fontFaces.paragraphs};
  }

  .dashboard-main-link {
    color: black;

    position: relative;
    display: inline-block;
    text-decoration: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 23px;
      height: 1px;
      width: 0;
      background-color: black;
      transition: width 0.2s ease-out;
    }

    &::before {
      left: 50%;
      transform: translateX(-100%);
    }

    &::after {
      right: 50%;
      transform: translateX(100%);
    }

    &:hover::before,
    &:hover::after {
      width: 50%;
    }

    @media (min-width: 991px) {
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
    }
  } 
  .dashboard-dropdown-link {
    color: white;
  }
  .primary-placeholder-and-text {
    color: ${primary};
    &::placeholder {
      color: ${primary};
    }
  }
  .primary-fill {
    fill: ${primary};
  }
  .primary-fill-hover {
    &:hover {
      fill: ${primary};
      transition: 0.2s ease-in-out;
    }
  }
  .secondary-fill {
    fill: ${secondary};
  }
  .white-fill {
    fill: ${white};
  }
  .main-fill {
    fill: ${main};
  }
  .dkGray-fill {
    fill: ${dkGray};
  }
  .dkBlue-fill {
    fill: ${dkBlue};
  }
  .accent-fill {
    fill: ${accent};
  }
  .white-text-hover:hover, .white-text-hover:focus {
    transition: 0.2s ease-in-out;
    color: ${white};
  }
  .primary-stroke {
    stroke: ${primary};
  }
  .secondary-stroke {
    stroke: ${secondary};
  }
  .white-stroke {
    stroke: ${white}
  }
  .path-primary-fill path{
    fill: ${primary};
  }
  .path-black-fill path{
    fill: ${black};
  }
  .path-secondary-fill path {
    fill: ${secondary};
  }
  .path-white-fill path {
    fill: ${white};
  }
  .path-main-fill path {
    fill: ${main};
  }
  .svg-path-white-fill-hover {
    &:hover {
      svg {
        path {
          fill: ${white};
          transition: all 0.3s ease-out
        }
      }
    }
  }
  .svg-path-primary-fill {
    &:hover {
      path {
        fill: ${secondary};
      }
    }
    path {
      fill: ${primary};
    }
  }

  .svg-path-black-fill {
    path {
      fill: ${black};
    }
  }

  .svg-path-primary-fill-on-courses-page {
    path {
      fill: ${primary};
    }
  }
  .svg-path-dkBlue-fill-on-courses-page {
    path {
      fill: ${dkBlue};
    }
  }
  .action-svg g {
    fill: ${dkBlue};
    stroke: ${dkBlue};
  }
  .action-svg:hover g {
    fill: ${secondary};
    stroke: ${secondary};
    cursor: pointer;
  }
  .my-dashboard-svg:hover g {
    fill: ${secondary};
    stroke: ${secondary};
    cursor: pointer;
  }
  .main-svg g {
    fill: ${main};
  }
  .white-svg g {
    fill: ${white};
  }
  .secondary-svg g {
    fill: ${secondary};
  }
  .secondary-stroke {
    stroke: ${secondary};
  }
  .disabled-svg-fill path {
    fill: ${accent};
  }
  .primary-border.primary-border {
    border-color: ${primary};
    border-bottom-color: ${primary};
    border-top-color: ${primary};
  }
  .primary-border-dkBlue {
    border-color: ${theme.colors.dkBlue};
    border-bottom-color: ${theme.colors.dkBlue};
    border-top-color: ${theme.colors.dkBlue};
  }
  .black-border {
    border-color: ${black};
    border-bottom-color: ${black};
    border-top-color: ${black};
  }
  .error-border {
    border-color: ${theme.colors.red};
    border-bottom-color: ${theme.colors.red};
    border-top-color: ${theme.colors.red};
  }
  .primary-border-hover{
    :hover{
      border-color: ${primary};
      border-bottom-color: ${primary};
      border-top-color: ${primary}
    }
  }
  .secondary-border-hover{
    :hover{
      border-color: ${secondary};
      border-bottom-color: ${secondary};
      border-top-color: ${secondary};
    }
  }
  .secondary-border {
    border-color: ${secondary};
    border-bottom-color: ${secondary};
    border-top-color: ${secondary};
  }
  .secondary-underline {
    text-decoration: underline;
    text-decoration-color: ${secondary};
  }
  .white-underline {
    text-decoration: underline;
    text-decoration-color: ${white};
    color: ${white};
  }
  .main-border {
    border-color: ${main};
    border-bottom-color: ${main};
    border-top-color: ${main};
  }
  .download-certificate-wrapper:hover {
    border-bottom: 4px solid;
    border-bottom-color: ${primary};
  }
  .light-table-border {
    border: 1px solid ${light};
  }
  .thead-padding {
    padding: 12px;
  }
  .table-cell-padding {
    padding: 4px;
  }
  .table-margin {
    margin: 20px 0 50px 0;
  }
  .ordered-list {
    list-style-type: decimal;
  }
  .small-acumen-academy-logo polygon:first-of-type {
    fill: ${secondary};
  }
  .small-acumen-academy-logo polygon:last-of-type {
    fill: ${primary};
  }
  .large-acumen-academy-logo polygon:first-of-type {
    fill: ${primary};
  }
  .large-acumen-academy-logo polygon:last-of-type {
    fill: ${secondary};
  }
  } 
  .secondary-large-acumen-academy-logo polygon:first-of-type {
    fill: ${secondary};
  }
  .secondary-large-acumen-academy-logo polygon:last-of-type {
    fill: ${primary};
  }
  .svg-child-circle-fill-primary svg circle {
    fill: ${primary};
  }
  .svg-child-circle-fill-secondary circle {
    fill: ${secondary};
  }
  .svg-child-circle-fill-white circle {
    fill: ${white};
  }
  .svg-child-circle-fill-light circle {
    fill: ${light};
  }
  .svg-child-circle-fill-dkBlue circle {
    fill: ${dkBlue};
  }
  .svg-child-polygon-fill-secondary polygon {
    fill: ${secondary};
  }
  .svg-child-polygon-fill-primary polygon {
    fill: ${primary};
  }

  .svg-path-accent-fill path {
    fill: ${theme.colors.accent};
  }

  .svg-path-accent-fill-on-courses-page path {
    &:hover {
        fill: ${secondary};
    }
    
    fill: ${theme.colors.accent};
  }
  
  .help-links {
    font-family: ${theme.fontFaces.help};
    font-size: 45px;
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    color: ${theme.colors.main};
  }
  .course-card-spacing {
    margin: 0 1.5em 2em 1.5em;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        width: 94vw;
        margin: 10px auto 0 auto;
    }
  }
  .course-list-download-link.course-list-download-link.course-list-download-link:hover {
    color: ${primary};
    cursor: pointer;
    svg {
      margin: 0;
      g {
        path {
          fill: ${primary};
        }
      }
    }
    &:hover {
      span {
        border-bottom: 2.5px solid ${theme.colors.primary};
      }
    }
  }
  .helper1350-breakpoint-1em-margin {
    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin: 1em 1em;
    }
  }
  .dynamic-form-input-wrapper {
    &:focus-within, &:focus {
      border-color: ${primary} !important;
      transition: all 0.2s ease-out;
      >div {
        border-color: ${primary} !important;
        transition: all 0.2s ease-out;
      }
    }
    &.has-errors {
      border-color: ${theme.colors.red} !important;
      transition: all 0.2s ease-out;
    }
  }
  button {
    &:disabled {
      background-color: ${accent};
      border: 1px solid ${accent};
      div {
        color: ${main};
      }
      &:hover {
        cursor: not-allowed;
      }
  }
}
  .white-border {
    border-color: ${white};
    border-bottom-color: ${white};
    border-top-color: ${white};
  }
  .course-catalog-hero-primary-card {
    background-color: ${primary};
    color: ${black};
  }

  .course-catalog-hero-secondary-card {
    background-color: ${secondary};
    color: ${black};
  }
  .course-catalog-primary-single-card {
    p {
      color: ${black};
    }
    color: ${black};
    background-color: ${primary};
  }
  .course-catalog-light-grey-single-card {
    background-color: ${secondary};
    h4 {
      color: ${main};
    }
    h2 {
      color: ${main};
    }
    &:hover {
      background-color: ${secondary};
      color: ${black};
      svg {
        path {
          fill: ${black};
        }
      }
      p {
        border-top-color: ${black};
        color: ${black};
      }
      h4,h2,a {
        color: ${black};
      }
    }
  }

  .course-catalog-hero-light-grey {
    background-color: ${light};
  }
  label > span.primary-checkbox-border-override.primary-checkbox-border-override {
      border-color: ${black};
  }
  label > span.secondary-checkbox-border-override.secondary-checkbox-border-override {
    border-color: ${secondary};
}

.primary label input[type=radio]:checked ~ span:after  {
  background-color: ${primary};
}

/* Felowship Detail */

.primary-markdown {
  margin-left: 120px;
  margin-right: 120px;
  @media only screen and (max-width: ${theme.breakpoints.helper800}) {
    margin-left: 14px;
    margin-right: 14px;
  }
  a {
    color: ${primary};
  }
  strong {
    font-weight: bolder;
  }
  h1 {
    color: ${primary};
  }
  h2, h3, h4, h5, h6, h7 {
    color: ${primary};
  }
  ul {
    margin-left: 40px;
  }
  ul li:before {
    content: '\\25C6';
    margin-left: -30px;
    margin-right: 15px;
    color: ${accent};
  }
  p, li {
    margin: 30px 0px 30px 0px;
  }
}

.fellowship-faq {
  h3, h4 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 24px;
    margin: 50px 40px 30px 0;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin: 12px 14px 14px 0;
    }
  }
  p {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 24px;
    margin: 30px 40px 30px 0;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin: 12px 14px 14px 0;
    }
  }
}

// Fellowship Experience Starts

h2.fellowship-experience-headers {
  font-size: 53px;
  font-weight: bold;
  letter-spacing: -1.38px;
  line-height: 64px;
  margin: 24px 342px;
  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.94px;
    line-height: 28px;
    margin: 24px 0px;
  }
}

h3.fellowship-experience-heading {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.06px;
  line-height: 21px;
  margin: 0 342px 20px 342px;

  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    margin-left: 0px;
  }
}

p.fellowship-experience-paragraph {
    font-size: 20px;
    letter-spacing: -0.57px;
    line-height: 29px;
    margin-left: 342px;
    margin-right: 342px;
    margin-bottom: 20px;
    a {
      color: ${primary};
    }
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      margin-left: 0px;
      margin-right: 0px;
    }
}

.contact-us-paragraph {
  font-size: 20px !important;
  line-height: 29px !important;
  color: ${black};
}

ul.fellowship-experience-list {
  margin: 36px 0;
}

li.fellowship-experience-list-item {
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
  margin: 16px 342px 16px 342px;
  :before {
    content: "\u2B25";
    color: ${theme.colors.dkBlue};
    margin-left: -20px;
  }
  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

// Fellowship Experience Ends

.fellowship-regional-description-strong {
  display: block;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.18px;
  line-height: 24px;
  margin-top: 9px;
  margin-bottom: 15px;
  font-family: ${theme.fontFaces.headers};
}

.fellowship-regional-description {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 24px;
  margin: 30px 40px 30px 48px;

  @media only screen and (max-width: ${theme.breakpoints.helper800}) {
    margin: 12px 14px 14px 15px;
  }
}

.featured-fellows-bioAbout-heading {
  font-size: 2.15vw;
  font-weight: bold;
  letter-spacing: -1.04px;
  line-height: 48px;
  margin: 48px 20px 0 20px;

  @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
    font-size: 32px;
    letter-spacing: -1.04px;
    line-height: 32px;
    margin: 40px 20px 0 20px;
  }

  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    font-size: 24px;
    letter-spacing: -1.04px;
    line-height: 24px;
    margin: 30px 20px 0 20px;
  }
}

.feature-fellow-bioAbout-paragraph {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -0.22px;
	line-height: 30px;
  margin-left: 20px;
  margin: 10px 20px 0 20px;

  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    font-size: 20px;
    letter-spacing: -0.22px;
    line-height: 24px;
  }
}
.spin-up {
  transform: rotate(180deg);
  -webkit-animation: spinUp 1s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: spinUp 1s ease-in-out; /* Firefox < 16 */
  -ms-animation: spinUp 1s ease-in-out; /* Internet Explorer */
  -o-animation: spinUp 1s ease-in-out; /* Opera < 12.1 */
  animation: spinUp 1s ease-in-out;
}

.spin-down {
  transform: rotate(0deg);
  -webkit-animation: spinDown 1s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: spinDown 1s ease-in-out; /* Firefox < 16 */
  -ms-animation: spinDown 1s ease-in-out; /* Internet Explorer */
  -o-animation: spinDown 1s ease-in-out; /* Opera < 12.1 */
  animation: spinDown 1s ease-in-out;
}

.arrow-up {
  transform: rotate(180deg);
}

.arrow-down {
  transform: rotate(0deg);
}
.nav-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  width: 40px;
}

.nav-toggle .bar {
  height: 7px;
  width: 100%;
  background-color: ${dkBlue};
  transition: all 100ms ease-in-out;
}

.nav-toggle:hover {
  cursor: pointer;
}

.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 50px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 50px;
}

// Our Story Starts

.our-story-description {
  font-family: ${theme.fontFaces.headers};
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.45px;
  line-height: 32px;
  margin-bottom: 40px;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    font-size: 18px;
    letter-spacing: -0.39px;
    line-height: 26px;
    margin-bottom: 30px;
  }
}

.manifesto-description {
  font-family: ${theme.fontFaces.paragraphs};
  font-size: 20px;
  letter-spacing: -0.85px;
  line-height: 31px;
  margin-bottom: 38px;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    font-size: 15px;
    letter-spacing: -0.68px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.manifesto-description-strong {
  font-family: ${theme.fontFaces.paragraphs};
  font-size: 28px;
  letter-spacing: -0.85px;
  line-height: 31px;
  margin-bottom: 38px;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    font-size: 20px;
    letter-spacing: -0.68px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

// Our Story Ends

// EXPLORE LANDER SPECIFIC STYLES BEGINS
.redefine-icon-secondary-hover.redefine-icon-secondary-hover, .lead-icon-secondary-hover.lead-icon-secondary-hover {
  &:hover {
    transition: all 0.3s ease-out;
    g {
      g {
        path {
          :nth-child(2) {
            fill: ${secondary};
          }
          :nth-child(1), :nth-child(3) {
            fill: ${white};
          }
        }
      }
    }
    .explore-lander-lead-icon-primary, .explore-lander-redefine-icon-primary {
      g g path {
        :nth-child(2) {
          fill: ${primary};
        }
      }
    }
  }
  g {
    g {
      path {
      :nth-child(2) {
        fill: ${white};
      }
      :nth-child(1), :nth-child(3) {
        fill: ${primary};
      }
    }
  }
}
}
​
.redefine-secondary-icon {
  g g path {
    :nth-child(2) {
      fill: ${white};
    }
    :nth-child(1),  :nth-child(3) {
      fill: ${secondary};
    }
  }
}
​
.redefine-secondary-icon__primary-bg {
  g g path {
    :nth-child(2) {
      fill: ${primary};
    }
    :nth-child(1),  :nth-child(3) {
      fill: ${secondary};
    }
  }
}
​
.redefine-path-white-hover__child__path {
  &:hover {
    svg g g path {
      :nth-child(2) {
        fill: ${secondary};
        transition: all 0.3s ease-out;
      }
      :nth-child(1),  :nth-child(3) {
        fill: ${white};
        transition: all 0.3s ease-out;
      }
    }
  }
}
​
.build-secondary-icon {
  g g path {
    :nth-child(1) {
      fill: ${secondary};
      transition: all 0.3s ease-out;
    }
    :nth-child(2), :nth-child(3) {
      fill: ${white};
      transition: all 0.3s ease-out;
    }
  }
}
​
.lead-secondary-icon {
  g g path {
    :nth-child(2) {
      fill: ${white};
    }
    :nth-child(1), :nth-child(3) {
      fill: ${secondary};
    }
  }
}
​
.lead-secondary-icon__primary-bg {
  g g path {
    :nth-child(2) {
      fill: ${primary};
      transition: all 0.3s ease-out;
    }
    :nth-child(1), :nth-child(3) {
      fill: ${secondary};
      transition: all 0.3s ease-out;
    }
  }
}
​
.lead-path-white-hover__child__path {
  &:hover {
    svg g g path {
      :nth-child(2) {
        fill: ${secondary};
        transition: all 0.3s ease-out;
      }
      :nth-child(1), :nth-child(3) {
        fill: ${white};
        transition: all 0.3s ease-out;
      }
    }
  }
}
​
.build-path-white-hover__child__path {
  &:hover {
    svg g g path {
      :nth-child(1) {
          fill: ${white};
          transition: all 0.3s ease-out;
        }
        :nth-child(2), :nth-child(3) {
          fill: ${secondary};
          transition: all 0.3s ease-out;
        }
    }
  }
}
​
.innovate-path-white-hover__child__path, .imagine-path-white-hover__child__path, .fund-path-white-hover__child__path {
    &:hover {
      svg g g {
        path {
          fill: ${white};
          transition: all 0.3s ease-out;
        }
      }
    }
  }
​
.explore-lander-redefine-icon-primary.explore-lander-redefine-icon-primary {
  g {
    g {
      path {
        :nth-child(2) {
          fill: ${primary};
        }
        :nth-child(1), :nth-child(3) {
          fill: ${white};
        }
      }
    }
  }
}
​
svg.explore-lander-lead-icon-primary.explore-lander-lead-icon-primary {
  g {
    g {
      path {
        :nth-child(2) {
          fill: ${primary};
        }
        :nth-child(1), :nth-child(3) {
          fill: ${white};
        }
      }
    }
  }
}
​
.imagine-icon-secondary-hover.imagine-icon-secondary-hover, .fund-icon-secondary-hover.fund-icon-secondary-hover, .innovate-icon-secondary-hover.innovate-icon-secondary-hover {
  &:hover {
    transition: all 0.3s ease-out;
    g {
      g {
        path {
            fill: ${white};
        }
      }
    }
  }
  g {
    g {
      path {
        fill: ${primary};
      }
    }
  }
}
​
.explore-lander-innovate-icon-primary.explore-lander-innovate-icon-primary, .explore-lander-fund-icon-primary.explore-lander-fund-icon-primary, .explore-lander-imagine-icon-primary.explore-lander-imagine-icon-primary {
  g {
    g {
      path {
        fill: ${white}
      }
    }
  }
  &:hover {
    transition: all 0.3s ease-out;
    g {
      g {
        path {
          fill: ${white};
        }
      }
    }
  }
}
​
.build-icon-secondary-hover.build-icon-secondary-hover {
  &:hover {
    g {
      g {
        path {
          :nth-child(1) {
            fill: ${white};
            transition: all 0.3s ease-out;
          }
          :nth-child(2), :nth-child(3) {
            fill: ${secondary};
            transition: all 0.3s ease-out;
          }
        }
      }
    }
    .explore-lander-build-icon-primary {
      g g path {
        :nth-child(1) {
          fill: ${white};
        }
        :nth-child(2), :nth-child(3) {
          fill: ${primary};
        }
      }
    }
  }
  g {
    g {
      path {
        :nth-child(1) {
          fill: ${primary};
        }
        :nth-child(2), :nth-child(3) {
          fill: ${white};
        }
      }
    }
  }
}
​
.explore-lander-build-icon-primary.explore-lander-build-icon-primary {
  g {
    g {
      path {
        :nth-child(1) {
          fill: ${white};
        }
        :nth-child(2), :nth-child(3) {
          fill: ${primary};
        }
      }
    }
  }
}
//EXPLORE LANDER SPECIFIC STYLES ENDS

// PAYMENT STARTS
.card_input, .exp_input, .cvc_input {
  border: 2px solid ${accent};
  padding: 22px;
  &:focus, &:focus-within, &:active {
    border-color: ${primary};
    outline: none;
  }
  label {
    color: purple;
  }
}

.cvc_input {
  margin-left: 10%;
  width: 90%;
}
// PAYMENT ENDS
//FAQ SPECIFIC STYLES STARTS

.faq-page-span {
  font-family: ${theme.fontFaces.paragraphs};
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
  margin: 10px 40px 0px 0;
  display: block;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin: 0;
      font-size: 15px;
      letter-spacing: -0.43px;
      line-height: 20px;
    }

}

.faq-page-li::before {
  content: '-';
  margin-right: 8px;
  font-family: ${theme.fontFaces.paragraphs};
}

.faq-page-li {
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
}

.faq-page-link {
  color: ${royalBlue};

  &:visited {
    color: ${royalBlue};
  }
}

.faq-page-strong {
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
  font-weight: bold;
  font-family: ${theme.fontFaces.paragraphs};
}

.faq-page-italics {
  font-style: italic;
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
}

.faq-page-p {
  font-size: 20px;
  letter-spacing: -0.57px;
  line-height: 31px;
}


//FAQ SPECIFIC STYLES ENDS

// Schedule Specific Styles
  .schedule {
    > h2 {
      color: ${theme.colors.dkBlue};
      font-family: ${theme.fontFaces.headers};
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      line-height: 64px;
      margin-top: 27px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        line-height: 24px;
      }
    }

    > ul {
      margin-top: 14px;
      margin-bottom: 97px;
      margin-left: 223px;
      list-style-position: inside;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-left: 100px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 0px;
        margin-bottom: 40px;
      }

      > li {
        color: ${theme.colors.main};
        font-family: ${theme.fontFaces.paragraphs};
        font-size: 20px;
        letter-spacing: -0.57px;
        line-height: 31px;
        margin-top: 20px;
        :before {
          content: "\u2B25";
          color: ${theme.colors.accent};
          margin-right: 20px;
        }

        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
          font-size: 15px;
          letter-spacing: -0.43px;
          line-height: 20px;
        }
      }
    }
  }
        
// End Schedule Specific Styles

// Accelerator Detail Specific Styles
  .accelerator-faq {
    h2, h3, h4 {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-left: 48px;
      margin-bottom: 10px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 12px 14px 14px 15px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.18px;
      line-height: 24px;
      margin: 0px 40px 20px 48px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 12px 14px 14px 15px;
      }
    }    
    ul {
      margin-top: 14px;
      margin-bottom: 20px;
      margin-left: 48px;
      margin-right: 48px;
      list-style-position: inside;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 0px;
      }

      li {
        color: ${theme.colors.main};
        font-family: ${theme.fontFaces.paragraphs};
        font-size: 20px;
        letter-spacing: -0.57px;
        line-height: 31px;
        margin-top: 20px;
        :before {
          content: "\u2B25";
          color: ${theme.colors.accent};
          margin-right: 20px;

          @media only screen and (max-width: ${theme.breakpoints.helper800}) {
            margin-right: 5px;
          }
        }

        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
          font-size: 15px;
          letter-spacing: -0.43px;
          line-height: 20px;
          margin: 12px 14px 14px 15px;
        }
      }
    }
  }

// End Accelerator Detail Specific Styles

.markdown-paragraph {
    font-family: ${theme.fontFaces.paragraphs};
    font-size: 20px;
    letter-spacing: -0.57px;
    line-height: 31px;
    margin-bottom: 1em;
  
    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 15px;
      letter-spacing: -0.43px;
      line-height: 20px;
    }
}

.markdown-link {
  color: ${theme.colors.primary};
}

// Event Accordion

.event-accordion {
  background-color: ${theme.colors.light};
  &:hover {
    background-color: ${primary};
    color: ${white};
.featured-foundry-member-card-markdown {
  h2 {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: normal;
  }
  p {
    display: none;
  }
}

.foundry-member-grid-card-markdown {
  h2 {
    font-size: 15px;
    font-weight: bold;
    font-family: ${theme.fontFaces.paragraphs};
    line-height: 19px;
    letter-spacing: 0em;
    margin-bottom: 5px;

    @media only screen and (max-width: ${theme.breakpoints.helper750}) {

    }
  }
  p {
    font-weight: medium;
  }
}

.foundry-member-modal-card-markdown {
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0em;
    margin-bottom: 5px;

    @media only screen and (max-width: ${theme.breakpoints.helper750}) {
     
    }
  }
  p {
    display: none;
  }
}

// Global Styles

@keyframes slideUp {
  from {
    max-height: 300px;
  }

  to {
    max-height: 0;
  }
}

@keyframes slideDown {
  from {
    max-height: 0;
  }

  to {
    max-height: 300px;
  }
}

.global-mobile-width {
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    padding: 30px 14px;
  }
}

b {
  font-weight: bold;
}
i {
    font-style: italic;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s linear 1s, opacity 300ms;
}
.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility 1s linear 1s, opacity 300ms;
}
.paginator {
  > ul {
    > li {
      > a {
        color: ${primary};
      }
    }
  }

  @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
    > ul {
      > li {
        background-color: ${primary};
      }
    }
  }
}

 `;

export default globalStylesWrapper;
