import { css } from "@emotion/core";
import { theme } from "./theme";

const { primary, secondary, accent, white, main, black } = theme.colors;

const buttons = css`
  .primaryBtn,
  .secondaryBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
    border: 1px solid transparent;
    border-radius: 0;
    background-color: 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    min-width: 10.375rem;
    height: 2.25rem;
    font-size: 1.1875rem;
    font-weight: 700;
  }
  .primaryBtn {
    background-color: ${primary};
    color: ${main};
    &:hover {
      background-color: ${secondary};
    }
    &:disabled {
      background-color: ${accent};
    }
  }
  .secondaryBtn {
    border: 2px solid ${primary};
    transition: border-color 0.25s ease-out, color 0.25s ease-out;
    color: ${primary};
    background-color: transparent;
    &:hover {
      color: ${secondary};
      border-color: ${secondary};
    }
    &:disabled {
      color: ${accent};
      border-color: ${accent};
    }
  }
  .white-button,
  .black-button,
  .disabled-button {
    font-family: ${theme.fontFaces.headers};
    border-radius: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 17px;
    padding: 15.5px 22.5px;
  }
  .white-button {
    color: ${white};
    &:visited {
      color: ${white};

      &:hover {
        color: ${main};
      }
    }
    border: 1px solid ${white};
    &:active,
    &:hover {
      background-color: ${white};
      color: ${main};
    }
  }
  .black-button {
    border: 1px solid ${main};
    color: ${black};
    &:active,
    &:hover {
      background-color: ${main};
      color: ${white};
    }

    &:visited {
      color: ${main};

      &:hover {
        color: ${white};
      }
    }
  }
  .disabled-button {
    background-color: ${accent};
    color: ${white};
    border: 1px solid ${accent};
    cursor: not-allowed;
  }
`;

export default buttons;
