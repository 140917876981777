import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultImage: image
          twitterUsername
          fbAppId
        }
      }
      allContentfulAsset(filter: { title: { eq: "Website Default Image" } }) {
        nodes {
          file {
            url
          }
        }
      }
    }
  `);

  return data;
};

export default useSiteMetadata;
