import { Global, css } from "@emotion/core";
import Styled from "@emotion/styled";
import { ThemeProvider, withTheme } from "emotion-theming";
import Markdown from "markdown-to-jsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import SEO from "../../components/Layout/SE0";
import Banner from "../../components/shared/Global/Banner";
import useCookieData from "../../hooks/cookie-banner-data";
import useHeaderFooterData from "../../hooks/header-footer-data";
import useHelpData from "../../hooks/help-component-data";
import useNotificationBarData from "../../hooks/notification-bar-data";
import useSpanishHeaderFooterData from "../../hooks/spanish-header-footer-data";
import { setFooterHeight } from "../../state/actions";
import globalStylesWrapper from "../../utils/global-styles/global-styles";
import { theme } from "../../utils/global-styles/theme";
import { GlobalErrorHandler } from "../../utils/services/GlobalErrorHandler";
import { getUserDetails, isLoggedIn } from "../../utils/services/methods";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MarketingModal from "../shared/MarketingModal/MarketingModal";
import NotificationBar from "../shared/NotificationBar/NotificationBar";
import PageBackground from "./PageBackground";

const Layout = ({
  children,
  currentPath,
  loading,
  enforceWidth,
  isDetailView,
  isMarketingLanderTemplate,
  dispatch,
  title,
  description,
  image,
  pathname,
  isBlog,
  locale = "en-US",
  marketingModal,
  fullDeviceWidth = false,
  containerRef,
  animatedPartOfLogoRef,
}) => {
  isDetailView =
    isDetailView === undefined
      ? currentPath
        ? currentPath.substr(1).indexOf("/") > 0 // default: show help if we are a deep url
        : true
      : isDetailView;
  enforceWidth = enforceWidth === undefined ? true : false;
  const { header, footer, notifications } = useHeaderFooterData(),
    {
      spanishHeader,
      spanishFooter,
      spanishNotifications,
    } = useSpanishHeaderFooterData(),
    footerToUse = locale === "en-US" ? footer : spanishFooter,
    headerToUse = locale === "en-US" ? header : spanishHeader,
    { heading, contentModules } = useHelpData(),
    notificationBarData = useNotificationBarData(),
    ref = useRef(null),
    LoaderBackground = Styled("div")`
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  `,
    BodyWrapper = Styled("div")`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    z-index: 1;
    padding-top: ${
      typeof window !== "undefined" && window.location.pathname === "/"
        ? "0px"
        : "97px"
    };
  `,
    BannerText = (
      <Markdown
        options={{
          overrides: {
            a: {
              props: {
                className: "white-underline",
              },
            },
          },
        }}
      >
        {locale === "es"
          ? useCookieData()?.spanishCookieText?.nodes[0]?.contentModules[0]
              ?.description?.description
          : useCookieData()?.englishCookieText?.nodes[0]?.contentModules[0]
              ?.description?.description}
      </Markdown>
    ),
    [isNotificationBarShowing, setIsNotificationBarShowing] = useState(false),
    [isMarketingModalShowing, setIsMarketingModalShowing] = useState(false),
    [isMobileView, setMobileView] = useState(false),
    handleResize = () => {
      if (window.innerWidth < 801 && ref.current && ref.current.clientHeight) {
        dispatch(setFooterHeight(ref.current.clientHeight));
      }
    };

  useEffect(() => {
    let notificationDataFromLocalStorage = JSON.parse(
      localStorage.getItem("notification")
    );

    let marketingModalDataFromLocalStorage = JSON.parse(
      localStorage.getItem("marketingModal")
    );

    if (
      marketingModalDataFromLocalStorage?.description !==
        marketingModal?.description?.description &&
      !marketingModalDataFromLocalStorage?.showMarketingModal &&
      marketingModal &&
      currentPath !== "/learning-resources"
    ) {
      setIsMarketingModalShowing(true);
    } else {
      setIsMarketingModalShowing(false);
    }

    if (
      (notificationDataFromLocalStorage?.title !==
        notificationBarData.displayText ||
      !notificationDataFromLocalStorage?.title) &&
      currentPath !== "/learning-resources"
    ) {
      setIsNotificationBarShowing(true);
    }
    if (ref.current && ref.current.clientHeight)
      dispatch(setFooterHeight(ref.current.clientHeight));
    window.addEventListener("resize", handleResize);

    if (isMobileView) {
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  useEffect(() => {
    const user = getUserDetails();
    if (user && window?.clarity) {
      window?.clarity("identify", user?.email);
    }
  }, []);
  return (
    <GlobalErrorHandler>
      <SEO
        title={title}
        description={description}
        isBlog={isBlog}
        pathname={pathname}
        image={image}
        lang={locale.split("-")[0]}
      />
      {loading ? (
        <ThemeProvider theme={{ ...theme }}>
          <Global styles={globalStylesWrapper} />
          <LoaderBackground className="secondary-bg">
            <Loader
              className="svg-child-circle-fill-primary"
              type="ThreeDots"
              height={100}
              width={100}
              timeout={3000000}
            />
          </LoaderBackground>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={{ ...theme }}>
          {notificationBarData !== null && isNotificationBarShowing ? (
            <NotificationBar
              {...notificationBarData}
              isNotificationBarShowing={isNotificationBarShowing}
              setIsNotificationBarShowing={() => {
                setIsNotificationBarShowing(false);
              }}
            />
          ) : null}
          <BodyWrapper className="body-wrapper">
            <Global styles={globalStylesWrapper} />
            {isMarketingModalShowing && (
              <MarketingModal
                isMarketingModalShowing={isMarketingModalShowing}
                marketingModal={marketingModal}
                setIsMarketingModalShowing={setIsMarketingModalShowing}
              />
            )}

            {isMarketingLanderTemplate ? null : (
              <Header
                isLoggedIn={!!isLoggedIn()}
                headerLinks={headerToUse}
                footerLinks={footerToUse}
                notificationLinks={notifications.nodes}
                currentPath={currentPath}
                containerRef={containerRef}
                animatedPartOfLogoRef={animatedPartOfLogoRef}
              />
            )}
            <main>
              {enforceWidth
                ? PageBackground({ children, fullDeviceWidth })
                : children}
            </main>
            {currentPath !== "/cookies" && (
              <Banner verbiage={BannerText} dismissText="accept" />
            )}

            <div
              id="footer"
              css={css`
                display: grid;
                @media only screen and (max-width: ${theme.breakpoints
                    .mobile}) {
                }
              `}
              ref={ref}
            >
              <Footer
                {...footerToUse}
                locale={locale}
                isMarketingLanderTemplate={isMarketingLanderTemplate}
                isDetailView={isDetailView}
                heading={heading}
                contentModules={contentModules}
              />
            </div>
          </BodyWrapper>
        </ThemeProvider>
      )}
    </GlobalErrorHandler>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  currentPath: PropTypes.string,
  loading: PropTypes.bool,
  enforceWidth: PropTypes.bool,
  isDetailView: PropTypes.bool,
  isMarketingLanderTemplate: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect(null, null)(withTheme(Layout));
