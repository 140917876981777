/**
 * All RESTful API methods will return Errors that come back from the API and
 * the component that called the method will handle the display of the errors
 * to the user contextually. -RJH 11/2019
 */
import get from "lodash/get";
import Cookies from "universal-cookie";
import { removeUserDetails } from "./methods";

const cookies = new Cookies(),
  apiToken = process.env.GATSBY_apiToken,
  spaceId = process.env.GATSBY_spaceId,
  accessToken = process.env.GATSBY_accessToken,
  api_url = process.env.GATSBY_api_url,
  communityUrl = process.env.GATSBY_community_url,
  login_url = process.env.GATSBY_login_url,
  contentful_env = process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  axios = require("axios");
cookies.set("apiToken", apiToken, {
  secure: true,
  httpOnly: true,
});
/**
 * We configure the axios instance with the base public token
 * and credentials set to true -RJH 12/19
 */
const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.defaults.withCredentials = true;

const axiosContentfulInstance = axios.create({
  baseURL: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${contentful_env}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosContentfulInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${accessToken}`;

const axiosCommunityInstance = axios.create({
  baseURL: communityUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosCommunityInstance.defaults.headers.common["aact_k"] = apiToken;

/**
 * Formats an HTTP response for return to client
 * @class
 * @classdesc takes in a raw HTTP response from either Contentful or the
 * Acumen Academy API and formats it to be consistent for consumption.
 * -RJH 1/2020
 */
class AcumenResponse {
  /**
   * @constructor
   * @param {object} response
   * @param {boolean} isContentfulQuery
   */
  constructor(response, isContentfulQuery) {
    this.response = response;
    this.isContentfulQuery = isContentfulQuery;
    /**
     * @returns
     * @param {boolean} success
     * @param {ArrayOf} errors: {key: string, message: string}
     * @param {Response} data
     */
    this.formattedResponse = {
      success: true,
      errors: [],
      data: {},
    };
  }

  assessResponse() {
    if (this.isContentfulQuery) {
      return this.response.data && this.response.status === 200
        ? this.handleContentfulSuccess()
        : this.handleContentfulError();
    } else {
      return this.response.data && this.response.data.code === "SUCCESS"
        ? this.handleSuccess()
        : this.handleError();
    }
  }

  handleSuccess() {
    this.formattedResponse.data = { ...this.response.data.response };
    return this.formattedResponse;
  }

  handleContentfulSuccess() {
    this.formattedResponse.data = this.response.data.items.map(item => {
      const needCompletion = Object.values(item.fields).filter(i => i.sys),
        connectedEntries = this.response.data.includes.Entry,
        connectedAssets = this.response.data.includes.Asset;

      return item.fields;
    });
    return this.formattedResponse;
  }

  handleError() {
    this.formattedResponse.success = false;
    this.formattedResponse.errors =
      this.response.data.errors.length === 0
        ? [
            {
              key: this.response.data.message,
              message: this.response.data.message,
            },
          ]
        : [...this.response.data.errors];
    return this.formattedResponse;
  }

  handleContentfulError() {
    this.formattedResponse.success = false;
    this.formattedResponse.errors = this.response.data.errors.map(error => ({
      key: error.sys.type,
      message: error.sys.id,
    }));
    return this.formattedResponse;
  }
}

/**
 * Formats an HTTP Error response for return to client
 * @class
 * @classdesc takes in a raw HTTP Error response from either Contentful or the
 * Acumen Academy API and formats it to be consistent for consumption.
 * -RJH 1/2020
 */
class AcumenErrorResponse {
  /**
   * @constructor
   * @param {Error} error
   * @param {Boolean} isContentfulError
   */
  constructor(error, isContentfulError, locale) {
    this.error = get(error, "response.data", {
      response: {
        message:
          locale === "en-US"
            ? "Something went wrong! Please try again."
            : "¡Algo salió mal! Inténtalo de nuevo",
      },
      code: error.message,
    });
    this.isContentfulError = isContentfulError;
    /**
     * @returns
     * @param {boolean} success
     * @param {ArrayOf} errors: {key: string, message: string}
     * @param {Error} data
     */
    this.formattedResponse = {
      success: false,
      errors: [],
      data: {},
    };
  }
  handleError() {
    if (this.isContentfulError) {
      this.formattedResponse.errors.push({
        key: this.error.sys.id,
        message: this.error.message,
      });
    } else if (
      this.error.httpStatusCode == 401 ||
      (this.error.httpStatusCode == 400 && this.error.code === "UN_AUTHORIZED")
    ) {
      removeUserDetails();
      return window.location.replace(`${login_url}sso`);
    } else {
      if (this.error.errors && this.error.errors.length)
        this.formattedResponse.errors = [...this.error.errors];
      else
        this.formattedResponse.errors.push({
          key: this.error.code,
          message: this?.error?.response?.message,
        });
    }
    this.formattedResponse.data = this.error;
    return this.formattedResponse;
  }
}

/**
 * @param { Params } params
 * Creates a GET request
 */
export const _get = async params => {
  try {
    if (params.isPublic) {
      axiosInstance.defaults.headers.common["lck"] = apiToken;
    }
    const response = await axiosInstance.get(`${api_url}${params.endpoint}`),
      formatted = new AcumenResponse(response);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err);
    return formatted.handleError();
  }
};

/**
 *
 * @param { Params } objectOf{endpoint: string, data: objectOf{string}}
 * Creates a POST request
 */
export const _post = async params => {
  try {
    if (params.isPublic) {
      axiosInstance.defaults.headers.common["lck"] = apiToken;
    }
    const response = await axiosInstance.post(`${api_url}${params.endpoint}`, {
        data: params.args || {},
      }),
      formatted = new AcumenResponse(response);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err, null, params?.locale);
    return formatted.handleError();
  }
};

/**
 * @param { Params } params
 * Creates a PUT request
 */
export const _put = async params => {
  try {
    if (params.isPublic) {
      axiosInstance.defaults.headers.common["lck"] = apiToken;
    }
    const response = await axiosInstance.put(`${api_url}${params.endpoint}`, {
        data: params.args || {},
      }),
      formatted = new AcumenResponse(response);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err);
    return formatted.handleError();
  }
};

/**
 * @param { Params } params
 * Creates a DELETE request
 */
export const _delete = async params => {
  try {
    const response = await axiosInstance.delete(
        `${api_url}${params.endpoint}`,
        {
          data: params.args || {},
        }
      ),
      formatted = new AcumenResponse(response);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err);
    return formatted.handleError();
  }
};

export const _contentfulGet = async params => {
  try {
    const response = await axiosContentfulInstance.get(
      `/entries/?${params.endpoint}`
    );
    const formatted = new AcumenResponse(response, true);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err, true);
    return formatted.handleError();
  }
};

export const _contentfulPost = async params => {
  const query = params.query,
    variables = params.variables;
  try {
    const response = await axiosContentfulInstance.post(params.endpoint || "", {
      query,
      variables,
    });
    return response.data.data || "";
  } catch (e) {
    return e;
  }
};

/**
 * @param { Params } params
 * Creates a GET request
 */
export const _communityGet = async params => {
  try {
    const response = await axiosCommunityInstance.get(
        `${communityUrl}${params.endpoint}`
      ),
      formatted = new AcumenResponse(response);
    return formatted.assessResponse();
  } catch (err) {
    const formatted = new AcumenErrorResponse(err);
    return formatted.handleError();
  }
};
