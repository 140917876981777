import React from "react";
import { css } from "@emotion/core";
import PropTypes from "prop-types";

const ThemeSix = ({ iconColorClassName, width, height, styles }) => {
  return (
    <svg
      width={width}
      height={height}
      className={iconColorClassName}
      css={css`
        ${styles || ""}
      `}
      viewBox="0 0 3507.000000 2480.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,2480.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M17608 21645 c-2 -1 -113 -5 -248 -9 -135 -4 -267 -8 -295 -10 -103
-8 -201 -17 -235 -21 -19 -2 -64 -7 -100 -10 -36 -3 -76 -7 -90 -10 -14 -2
-50 -6 -80 -9 -30 -3 -64 -8 -76 -10 -12 -2 -45 -7 -74 -11 -29 -4 -62 -9 -74
-11 -11 -2 -38 -6 -61 -10 -68 -10 -386 -71 -460 -89 -38 -9 -81 -19 -95 -22
-45 -9 -55 -11 -55 -13 0 -1 -11 -4 -25 -6 -33 -6 -324 -87 -430 -119 -47 -15
-91 -27 -97 -27 -7 -1 -13 -4 -13 -8 0 -4 -6 -7 -12 -7 -22 0 -217 -67 -213
-72 2 -3 -7 -7 -19 -7 -42 -3 -466 -173 -673 -270 -247 -116 -331 -158 -327
-166 3 -4 -2 -5 -10 -2 -9 3 -16 1 -16 -5 0 -6 -3 -10 -7 -9 -11 3 -136 -62
-144 -74 -3 -5 -9 -9 -13 -9 -3 1 -29 -10 -57 -24 -45 -23 -49 -28 -37 -43 12
-15 11 -16 -4 -4 -15 12 -26 8 -83 -23 -38 -21 -62 -41 -58 -47 3 -7 1 -8 -6
-4 -11 7 -245 -129 -277 -161 -8 -8 -14 -12 -14 -9 0 7 -253 -164 -380 -257
-52 -38 -94 -74 -92 -79 1 -5 -1 -7 -6 -4 -5 3 -36 -15 -68 -40 -32 -25 -72
-56 -87 -67 -15 -12 -38 -30 -50 -40 -12 -10 -35 -28 -50 -40 -15 -12 -38 -30
-50 -40 -12 -11 -42 -35 -66 -55 -24 -21 -53 -45 -65 -55 -11 -11 -45 -40 -75
-65 -30 -26 -69 -60 -87 -77 -18 -16 -74 -68 -125 -115 -150 -138 -419 -412
-413 -422 3 -5 1 -7 -4 -6 -4 2 -31 -21 -58 -51 -27 -29 -58 -63 -69 -75 -28
-29 -138 -156 -161 -185 -10 -13 -28 -32 -38 -42 -11 -11 -17 -19 -14 -19 4 0
-6 -12 -20 -27 -15 -16 -41 -45 -57 -67 -17 -21 -38 -48 -49 -60 -10 -11 -44
-55 -75 -96 -31 -41 -62 -82 -69 -90 -7 -8 -13 -19 -15 -25 -1 -5 -11 -19 -22
-31 -48 -52 -65 -77 -58 -87 3 -7 2 -9 -2 -4 -11 9 -46 -43 -38 -56 3 -6 2 -7
-4 -4 -5 3 -34 -30 -63 -73 -29 -44 -57 -80 -61 -80 -5 0 -8 -3 -7 -8 3 -8
-111 -183 -126 -194 -6 -4 -7 -8 -2 -8 5 0 2 -8 -6 -17 -8 -10 -54 -83 -102
-163 -48 -80 -91 -152 -97 -160 -5 -8 -12 -23 -15 -32 -4 -10 -11 -18 -16 -18
-5 0 -6 -4 -3 -10 3 -5 1 -10 -4 -10 -6 0 -11 -5 -11 -11 0 -6 -16 -38 -35
-71 -20 -33 -37 -66 -38 -72 -1 -6 -5 -13 -8 -16 -17 -15 -79 -145 -75 -157 3
-8 2 -11 -4 -8 -8 5 -45 -67 -159 -304 -11 -24 -18 -47 -16 -51 3 -4 -1 -10
-8 -13 -6 -2 -18 -22 -26 -43 -8 -22 -25 -64 -39 -94 -67 -149 -162 -386 -162
-404 0 -9 -4 -16 -8 -16 -8 0 -10 -7 -14 -50 -1 -10 -4 -17 -7 -15 -7 8 -81
-217 -74 -228 3 -5 2 -7 -4 -4 -5 3 -15 -15 -23 -41 -14 -51 -14 -50 -23 -72
-3 -8 -11 -33 -17 -55 -6 -22 -14 -47 -17 -55 -7 -17 -10 -27 -17 -55 -3 -11
-15 -51 -27 -90 -12 -38 -22 -82 -23 -97 -1 -14 -4 -24 -7 -22 -4 2 -10 -13
-15 -34 -4 -20 -8 -41 -10 -47 -2 -5 -5 -19 -8 -30 -3 -11 -9 -35 -15 -53 -5
-18 -10 -33 -9 -35 0 -1 -5 -20 -10 -42 -6 -22 -13 -51 -16 -65 -3 -14 -7 -34
-10 -45 -39 -161 -53 -241 -43 -253 8 -10 7 -12 -2 -10 -8 2 -15 -4 -16 -12
-4 -24 -13 -72 -20 -105 -31 -160 -38 -205 -39 -247 0 -20 -4 -34 -8 -31 -7 4
-10 -17 -12 -109 -1 -20 -4 -34 -8 -31 -5 2 -7 -6 -7 -19 1 -13 -1 -39 -5 -58
-3 -19 -8 -57 -11 -85 -3 -27 -7 -57 -10 -65 -2 -8 -6 -46 -10 -85 -3 -38 -7
-83 -9 -100 -9 -65 -10 -111 -1 -124 5 -10 4 -12 -3 -7 -12 7 -13 -3 -16 -151
-1 -29 -3 -53 -6 -53 -3 0 -6 -35 -7 -78 0 -42 -3 -135 -6 -207 -3 -89 -1
-135 7 -144 8 -11 8 -13 -1 -7 -10 5 -12 -35 -11 -186 1 -106 5 -201 10 -210
5 -10 4 -19 -2 -23 -8 -5 -7 -12 2 -23 7 -9 8 -13 3 -10 -6 4 -10 -6 -9 -25 1
-18 5 -93 8 -167 4 -74 9 -153 12 -175 2 -22 7 -69 9 -105 5 -70 12 -148 20
-220 3 -25 7 -63 10 -85 11 -105 18 -152 27 -169 8 -14 7 -17 -3 -11 -7 5 -10
4 -6 -2 6 -10 10 -32 21 -115 14 -108 20 -134 28 -145 7 -9 7 -13 -1 -13 -8 0
-9 -6 -2 -17 5 -10 12 -40 16 -68 9 -64 13 -84 19 -115 3 -14 13 -61 22 -105
10 -44 21 -88 27 -99 5 -10 5 -16 0 -13 -5 3 2 -36 14 -86 13 -51 25 -99 26
-107 15 -74 62 -248 67 -245 3 2 5 -9 4 -23 -1 -15 3 -33 9 -39 5 -7 6 -13 2
-13 -4 0 -2 -7 5 -15 7 -9 14 -28 16 -43 4 -34 6 -42 33 -131 13 -41 24 -81
26 -90 2 -9 13 -40 24 -68 10 -29 17 -53 13 -53 -3 0 -1 -5 6 -12 7 -7 12 -19
12 -28 0 -9 8 -32 19 -53 10 -20 14 -34 7 -30 -6 4 -5 -1 3 -10 7 -9 10 -17 6
-17 -4 0 -1 -7 6 -16 8 -9 14 -22 15 -28 0 -6 2 -19 2 -29 1 -10 6 -16 11 -13
5 3 9 0 8 -7 -1 -31 4 -48 12 -43 5 4 7 -4 3 -16 -4 -16 -2 -19 8 -13 8 5 11
4 7 -2 -9 -14 28 -115 38 -105 4 4 5 1 1 -6 -4 -7 2 -28 13 -48 12 -19 17 -33
12 -30 -5 3 6 -27 23 -68 17 -41 35 -71 40 -68 5 3 6 0 2 -7 -4 -7 -1 -26 7
-44 8 -18 29 -66 47 -107 18 -41 46 -103 61 -138 16 -34 29 -65 29 -68 0 -3
10 -20 22 -37 13 -18 22 -34 20 -37 -5 -9 41 -100 50 -100 5 0 7 -4 3 -10 -3
-5 6 -27 20 -50 14 -22 24 -40 22 -40 -4 0 62 -129 127 -246 27 -49 59 -107
71 -130 32 -60 51 -85 59 -77 4 4 4 -1 0 -11 -6 -15 -4 -17 6 -11 9 6 11 4 5
-4 -4 -8 1 -25 14 -42 11 -15 21 -31 21 -34 0 -4 11 -21 25 -39 14 -18 25 -38
25 -44 0 -7 5 -12 10 -12 6 0 10 -5 10 -11 0 -6 14 -30 30 -55 17 -24 28 -44
25 -44 -3 0 4 -9 15 -20 11 -11 20 -26 20 -34 0 -8 12 -24 28 -36 15 -12 22
-19 15 -16 -11 6 95 -157 165 -254 18 -25 41 -56 50 -69 82 -114 251 -336 281
-368 16 -18 27 -33 23 -33 -4 0 -2 -4 3 -8 6 -4 24 -23 40 -43 155 -183 218
-254 226 -254 4 0 11 -4 16 -9 5 -5 3 -6 -4 -2 -23 13 -14 0 33 -50 25 -27 50
-48 56 -46 5 1 7 -2 3 -8 -6 -10 398 -420 545 -551 180 -161 260 -230 260
-226 0 2 24 -19 52 -45 29 -27 58 -46 66 -43 7 2 10 1 7 -4 -6 -11 142 -127
157 -123 5 1 7 -1 4 -6 -2 -4 7 -17 21 -28 15 -10 50 -36 77 -56 100 -72 141
-103 146 -108 3 -3 13 -9 22 -15 10 -5 65 -43 124 -84 59 -41 113 -74 121 -74
7 0 11 -2 8 -5 -6 -6 30 -34 75 -59 19 -10 55 -32 79 -48 24 -17 51 -34 60
-40 9 -5 19 -14 23 -20 5 -6 8 -4 8 4 0 13 2 12 13 0 15 -20 231 -150 289
-176 15 -6 60 -31 99 -53 39 -23 77 -43 84 -44 7 -1 19 -7 26 -13 31 -24 141
-73 151 -67 7 5 8 2 3 -6 -6 -10 -4 -12 9 -7 9 4 15 3 12 -2 -5 -8 74 -50 82
-43 1 1 10 -4 18 -13 9 -8 20 -13 25 -10 5 4 9 1 9 -6 0 -7 3 -9 7 -6 3 4 15
-1 26 -10 10 -10 21 -17 23 -15 2 1 18 -5 34 -13 50 -26 34 -10 -46 47 -149
105 -418 318 -523 413 -183 165 -343 323 -456 450 -38 43 -77 85 -86 94 -18
18 -190 229 -229 280 -14 18 -30 38 -36 44 -17 20 -117 158 -159 222 -22 33
-43 62 -46 65 -3 3 -46 70 -96 150 -218 350 -418 754 -539 1092 -15 40 -31 84
-37 98 -6 14 -11 27 -12 30 -1 3 -6 20 -10 37 -4 18 -11 30 -16 27 -5 -3 -6 2
-3 10 3 8 2 21 -2 28 -5 7 -9 15 -9 18 -3 24 -30 110 -35 110 -3 0 -6 8 -7 18
-1 18 -10 57 -13 57 -1 0 -3 7 -5 15 -2 8 -6 24 -9 35 -5 19 -23 85 -31 115
-3 13 -30 127 -41 172 -9 41 -15 67 -29 138 -8 41 -17 86 -20 100 -3 14 -7 41
-10 60 -3 19 -7 46 -10 59 -2 13 -7 49 -10 80 -4 31 -8 63 -11 72 -3 9 -7 38
-9 63 -3 26 -7 64 -10 84 -3 20 -7 73 -11 117 -3 44 -8 96 -10 115 -2 19 -7
159 -10 310 -3 151 -8 278 -10 282 -3 4 0 8 6 8 8 0 7 6 -1 21 -6 12 -7 19 -1
15 6 -3 9 17 8 52 -1 31 2 76 5 100 4 27 2 42 -5 42 -8 0 -8 2 0 8 10 7 17 68
18 184 1 31 4 54 9 51 4 -2 7 8 8 24 1 70 16 183 24 191 3 3 -1 12 -8 20 -9
13 -9 14 1 8 8 -5 12 1 13 18 2 32 6 65 8 69 2 1 4 16 6 32 3 25 15 88 20 105
1 4 15 87 20 120 0 3 4 11 8 18 5 6 6 12 4 12 -2 0 2 26 10 58 7 31 16 68 19
82 3 14 12 50 20 80 8 30 17 64 19 75 3 11 14 51 25 89 10 38 17 73 13 79 -3
6 -1 7 5 3 7 -4 13 -1 13 8 2 17 5 35 8 39 2 1 4 13 7 26 2 14 9 30 14 36 6 7
5 16 -3 25 -6 7 -7 10 -1 7 11 -6 86 190 88 230 1 11 3 20 6 18 3 -2 16 24 30
58 13 34 30 78 38 97 8 19 14 37 14 40 0 3 5 14 11 25 6 11 10 23 9 28 -1 4 2
7 8 7 6 0 9 3 8 8 -1 4 8 29 20 55 12 27 22 53 22 58 0 5 5 9 12 9 6 0 9 3 6
6 -3 3 19 56 50 117 31 62 56 118 55 125 0 6 3 12 8 12 4 0 16 17 25 38 10 20
26 50 37 65 10 15 16 35 13 43 -3 8 -2 12 4 9 10 -6 42 48 35 59 -3 3 -2 4 2
1 10 -8 54 75 46 88 -3 7 -2 9 2 4 11 -9 76 94 68 107 -3 5 -1 6 4 3 6 -4 21
14 34 38 13 25 27 45 31 45 5 0 8 5 8 11 0 6 7 17 16 26 8 9 13 22 10 30 -3 8
-2 12 3 9 5 -3 21 13 36 37 16 23 48 68 72 100 25 32 42 64 39 72 -3 8 0 12 7
9 7 -2 23 13 37 33 14 20 30 41 35 47 8 9 65 80 140 174 5 7 20 24 33 37 13
14 19 25 15 25 -4 0 0 5 10 11 9 6 15 14 12 20 -4 5 2 6 11 2 11 -4 15 -3 10
5 -3 6 4 18 16 26 12 9 20 16 17 16 -9 0 124 140 276 292 77 77 139 144 137
149 -1 6 2 8 8 4 6 -3 23 6 39 22 45 44 88 82 204 177 79 65 107 93 102 105
-5 12 -3 13 6 4 11 -11 52 14 107 65 7 6 16 12 20 12 4 0 23 15 42 33 20 17
40 33 45 34 6 2 17 8 25 15 8 7 47 35 87 62 40 27 70 54 66 59 -3 6 -1 7 6 3
6 -4 32 6 57 23 25 17 49 31 55 31 5 0 9 4 9 9 0 5 8 11 18 15 9 3 31 15 47
26 35 23 96 59 137 80 16 9 25 19 21 24 -4 4 -3 7 2 6 25 -5 37 1 31 16 -5 13
-4 14 7 6 10 -9 18 -8 33 2 10 7 48 28 84 47 36 19 68 38 71 42 3 5 10 9 15 9
23 2 44 11 44 19 0 5 3 8 8 7 8 -2 124 51 132 61 3 3 10 7 15 8 6 1 42 16 80
34 39 18 76 33 83 35 6 1 33 12 59 25 25 12 52 22 60 22 7 0 13 4 13 9 0 5 5
6 10 3 6 -3 10 -1 10 4 0 6 3 10 8 10 16 -3 72 14 72 22 0 5 3 8 8 7 17 -5 52
14 46 25 -5 9 -4 9 6 0 13 -12 43 -6 35 7 -3 5 5 6 17 2 16 -4 19 -2 13 8 -5
8 -4 11 2 7 9 -6 202 51 213 63 3 3 12 6 20 7 8 1 49 12 90 23 41 12 86 24 99
26 34 7 42 13 34 27 -4 7 1 6 11 -2 11 -9 20 -11 24 -5 4 5 14 10 22 10 30 1
139 28 135 33 -2 4 9 7 23 7 52 3 82 8 82 15 0 4 10 5 22 1 12 -3 19 -1 15 4
-3 5 7 9 21 8 15 0 54 6 87 13 33 8 75 15 93 16 24 1 31 5 27 16 -4 11 -3 11
9 2 10 -9 26 -10 48 -5 18 4 37 7 42 5 5 -1 16 1 25 5 15 6 37 9 99 13 13 0
21 4 19 9 -3 4 20 5 50 4 41 -3 54 -1 48 8 -6 9 -3 9 12 1 11 -6 22 -8 25 -6
2 3 42 8 89 11 46 3 95 8 109 9 14 2 67 3 119 4 82 1 92 3 87 17 -5 14 -4 15
9 4 17 -14 225 -20 225 -6 0 5 6 3 14 -3 10 -8 16 -9 21 -1 3 6 10 8 15 5 4
-3 27 -7 50 -10 29 -4 39 -2 35 6 -5 8 -2 8 11 2 11 -6 47 -10 82 -11 35 -1
68 -4 73 -8 5 -3 9 1 9 8 0 8 3 9 8 3 4 -6 21 -11 37 -12 87 -3 234 -15 272
-22 30 -6 43 -5 43 3 0 6 4 5 9 -3 5 -8 18 -12 30 -8 12 3 21 2 21 -2 0 -4 6
-8 13 -8 6 0 41 -4 77 -8 36 -5 76 -9 90 -10 14 -2 32 -5 40 -8 14 -5 62 -13
130 -22 14 -2 26 -4 28 -5 1 -1 5 -3 10 -4 29 -7 72 -16 102 -21 19 -3 67 -14
105 -24 39 -10 80 -18 92 -16 12 1 20 -2 17 -7 -3 -5 0 -8 7 -7 8 2 39 -5 69
-14 30 -10 78 -24 105 -32 28 -8 78 -23 112 -34 34 -10 64 -17 67 -13 3 3 6 0
6 -5 0 -6 11 -10 25 -9 13 2 23 0 20 -3 -2 -4 17 -13 43 -21 26 -7 73 -24 105
-37 32 -13 63 -20 69 -17 7 4 8 3 4 -4 -4 -7 -1 -12 9 -12 8 0 23 -4 33 -10
41 -22 146 -59 155 -53 6 3 7 1 3 -5 -4 -8 0 -12 11 -12 11 0 23 -4 28 -8 6
-5 28 -16 50 -24 22 -9 76 -33 120 -54 44 -21 82 -38 85 -39 3 0 34 -15 68
-33 35 -18 69 -29 75 -25 5 3 7 2 4 -4 -4 -6 17 -22 46 -37 71 -36 146 -78
179 -100 15 -10 33 -14 39 -10 8 5 9 3 5 -5 -5 -8 0 -14 13 -18 12 -3 21 -9
21 -14 0 -5 6 -9 12 -9 11 0 61 -30 152 -93 18 -12 26 -14 22 -6 -5 10 -4 9 6
-1 7 -8 47 -37 88 -64 41 -27 85 -58 97 -69 12 -11 27 -16 34 -12 7 5 10 4 6
-1 -3 -5 11 -21 31 -34 20 -14 42 -32 49 -40 7 -8 13 -12 13 -8 0 4 10 -2 23
-13 54 -48 147 -119 157 -119 6 0 8 -4 5 -10 -3 -5 -2 -10 4 -10 8 0 105 -77
157 -125 6 -5 49 -44 95 -85 46 -41 149 -140 229 -220 131 -131 184 -188 315
-337 22 -25 47 -54 57 -63 9 -10 14 -20 12 -23 -3 -2 9 -16 25 -30 16 -13 27
-28 24 -31 -4 -3 -1 -6 5 -6 7 0 23 -18 37 -40 14 -23 33 -49 43 -60 9 -10 22
-25 27 -32 6 -7 25 -32 43 -55 19 -24 31 -43 27 -43 -3 0 4 -10 15 -22 12 -13
29 -35 38 -50 9 -16 20 -28 24 -28 4 0 8 -5 8 -10 0 -12 41 -73 53 -78 4 -2 7
-9 7 -17 0 -7 7 -18 15 -25 8 -7 12 -16 9 -21 -3 -5 3 -9 12 -9 10 0 14 -3 11
-6 -10 -11 13 -45 25 -38 6 4 8 3 4 -4 -3 -6 22 -57 57 -114 35 -57 67 -113
71 -126 4 -12 11 -22 16 -22 5 0 12 -9 15 -20 3 -11 13 -27 21 -35 9 -9 11
-15 4 -15 -6 0 -2 -10 10 -22 11 -12 20 -27 20 -34 0 -6 7 -15 16 -18 8 -3 13
-10 10 -14 -3 -5 28 -77 69 -160 41 -84 75 -157 75 -163 0 -5 4 -8 9 -4 6 3 8
-4 6 -15 -2 -11 0 -20 6 -20 5 0 8 -3 7 -7 -1 -5 8 -30 20 -56 12 -27 25 -60
28 -73 3 -13 11 -21 17 -17 6 3 7 1 3 -5 -4 -6 0 -25 9 -42 8 -16 13 -30 9
-30 -4 0 -2 -6 3 -12 11 -14 74 -180 77 -204 1 -7 5 -17 9 -20 4 -4 7 -16 7
-26 0 -11 4 -16 10 -13 5 3 8 0 5 -7 -3 -7 3 -30 14 -50 10 -21 16 -36 12 -34
-4 3 3 -27 16 -67 13 -39 26 -83 28 -98 3 -16 10 -40 15 -54 14 -37 65 -238
74 -295 2 -8 9 -42 16 -75 14 -63 18 -88 19 -118 1 -10 4 -16 9 -14 4 3 8 -18
9 -46 1 -28 7 -54 15 -58 11 -7 11 -9 0 -9 -7 0 -10 -4 -7 -10 3 -5 9 -31 12
-57 3 -27 6 -52 7 -58 0 -5 6 -13 11 -17 6 -5 5 -8 -3 -8 -7 0 -11 -4 -8 -8 9
-15 28 -163 30 -229 1 -18 4 -33 7 -33 3 0 3 -14 1 -31 -4 -25 -2 -30 9 -24
11 7 12 6 3 -6 -5 -8 -9 -23 -8 -34 16 -142 26 -787 12 -779 -4 3 -6 -10 -6
-28 2 -35 -10 -203 -17 -253 -3 -16 -7 -57 -10 -90 -4 -33 -8 -61 -10 -64 -3
-2 1 -11 8 -19 9 -13 9 -14 0 -8 -9 5 -13 -1 -14 -21 0 -15 -2 -37 -4 -48 -2
-11 -6 -40 -9 -65 -3 -25 -7 -52 -10 -60 -7 -23 -18 -100 -20 -142 0 -20 -3
-34 -6 -31 -3 2 -10 -17 -16 -44 -7 -34 -7 -52 0 -62 8 -11 8 -13 -1 -7 -8 4
-13 2 -14 -6 0 -7 -2 -20 -5 -28 -2 -8 -8 -37 -14 -65 -6 -27 -14 -61 -17 -75
-4 -14 -7 -37 -7 -52 -1 -14 -4 -24 -8 -22 -4 3 -10 -12 -14 -33 -4 -21 -9
-42 -13 -48 -3 -5 -6 -17 -7 -25 0 -8 -4 -23 -8 -32 -4 -9 -4 -22 0 -29 4 -8
3 -9 -4 -5 -8 4 -13 -1 -13 -13 -1 -12 -2 -27 -3 -33 -1 -7 -3 -15 -6 -18 -8
-8 -103 -298 -101 -306 2 -4 -3 -10 -10 -15 -10 -6 -10 -11 1 -23 11 -14 11
-16 -1 -11 -10 4 -21 -14 -37 -62 -23 -66 -54 -140 -73 -176 -5 -10 -7 -23 -3
-29 4 -7 3 -8 -4 -4 -7 4 -12 2 -12 -4 0 -17 -19 -58 -126 -275 -111 -226
-137 -271 -309 -553 -41 -66 -90 -138 -99 -144 -7 -5 -7 -8 -1 -8 6 0 6 -3 -1
-8 -6 -4 -37 -45 -69 -92 -33 -47 -63 -89 -67 -95 -5 -5 -25 -32 -46 -60 -20
-27 -42 -55 -47 -61 -6 -6 -28 -34 -50 -62 -21 -29 -43 -52 -47 -52 -4 0 -8
-5 -8 -11 0 -7 -10 -20 -22 -30 -11 -11 -18 -19 -15 -19 4 0 -10 -16 -29 -35
-31 -31 -34 -38 -22 -53 11 -15 11 -15 -3 -5 -14 11 -24 4 -70 -49 -30 -34
-79 -88 -110 -120 -31 -32 -54 -58 -50 -58 3 0 -7 -9 -24 -20 -16 -11 -27 -20
-23 -20 3 0 -21 -26 -54 -57 -34 -32 -76 -72 -94 -89 -18 -17 -30 -35 -26 -39
4 -5 2 -5 -4 -2 -6 3 -20 -4 -31 -16 -11 -12 -31 -31 -45 -42 -30 -24 -144
-123 -169 -147 -10 -9 -27 -22 -39 -28 -11 -6 -17 -16 -14 -22 4 -6 3 -8 -3
-5 -6 4 -24 -6 -42 -21 -17 -15 -51 -42 -76 -61 -25 -18 -42 -37 -39 -42 3 -5
-1 -7 -8 -4 -7 3 -25 -6 -40 -20 -15 -14 -32 -25 -38 -25 -5 0 -10 -4 -10 -8
0 -4 -15 -16 -32 -27 -18 -10 -35 -21 -38 -24 -3 -3 -24 -19 -47 -35 -24 -16
-43 -30 -43 -33 0 -2 19 7 43 19 23 12 44 22 47 23 24 4 88 41 83 48 -3 6 -2
7 4 4 15 -10 74 16 69 30 -3 7 1 10 8 7 8 -3 60 19 117 48 57 29 110 52 116
51 7 0 10 3 7 8 -3 5 2 9 10 9 9 0 16 5 16 12 0 6 3 9 6 5 3 -3 29 8 57 25 29
17 58 31 65 31 6 0 12 3 12 6 0 3 14 12 30 19 17 8 27 18 24 24 -4 7 -2 8 4 4
12 -7 56 17 47 25 -2 3 5 6 17 6 11 1 28 9 37 17 10 9 23 16 29 16 7 0 12 4
12 10 0 5 3 9 8 8 8 -3 64 32 74 47 4 5 8 6 8 1 0 -5 7 -2 16 5 9 7 24 18 35
24 10 5 50 30 89 54 61 38 69 46 58 60 -11 14 -11 14 4 3 14 -11 21 -11 42 3
14 10 23 22 19 28 -3 6 -1 7 5 3 10 -6 97 44 112 64 3 4 16 13 29 20 14 7 30
17 35 22 6 5 56 41 110 80 55 38 104 74 110 79 6 5 23 19 39 30 15 11 24 25
21 31 -4 7 -2 8 4 4 14 -9 132 75 127 91 -2 7 1 10 8 7 7 -2 35 16 62 41 28
24 53 45 57 45 4 0 17 10 30 23 13 13 52 48 88 78 35 30 75 63 87 73 12 10 20
24 16 29 -3 6 -1 7 5 3 13 -8 55 31 47 43 -2 5 3 8 13 7 9 0 16 5 15 11 -2 7
0 12 5 11 15 -5 54 30 47 43 -5 8 -4 10 3 5 7 -4 31 12 57 38 25 25 45 43 45
40 0 -3 16 13 35 36 19 23 35 40 35 37 0 -7 167 161 290 292 58 61 104 118
104 126 -1 8 2 12 8 9 5 -3 20 6 33 20 13 14 22 26 20 26 -3 0 13 18 35 40 22
22 38 40 35 40 -2 0 6 10 18 23 125 131 472 581 461 599 -4 6 -3 8 3 5 12 -8
36 17 26 26 -3 4 -1 7 5 7 5 0 21 17 33 38 13 20 29 43 37 51 7 7 11 20 8 27
-3 8 0 11 10 7 7 -3 14 0 14 6 0 12 61 104 73 109 4 2 7 9 7 17 0 7 7 18 15
25 8 7 12 16 9 21 -3 5 -1 9 4 9 14 0 93 131 86 143 -4 7 -4 9 1 5 7 -7 45 32
45 46 0 5 73 128 110 186 6 8 13 22 17 30 4 8 17 32 30 53 13 21 21 43 17 49
-4 6 -3 8 3 5 5 -3 20 15 33 41 13 26 32 59 42 74 11 16 16 32 13 38 -3 5 -1
10 5 10 6 0 16 13 21 30 6 16 15 30 21 30 5 0 7 4 3 9 -3 5 2 17 11 25 8 9 12
16 8 16 -3 0 -1 7 6 15 6 8 8 20 4 27 -4 7 -3 9 1 5 5 -4 19 11 31 35 21 40
21 44 6 63 -16 19 -16 19 1 6 16 -13 31 -9 32 7 1 4 6 17 13 28 6 12 8 24 5
28 -3 3 -1 6 6 6 8 0 11 6 7 16 -3 8 -2 13 3 9 9 -5 37 62 33 78 -1 4 2 7 6 7
5 0 19 24 32 52 12 29 33 76 45 103 73 163 125 297 119 308 -4 6 -3 9 1 4 11
-9 47 82 38 96 -3 6 -1 7 5 3 7 -4 12 -4 13 1 2 18 8 40 20 68 6 17 13 35 15
40 2 6 32 95 69 198 36 104 63 193 59 198 -3 5 -1 9 5 9 9 0 28 65 23 83 -1 4
2 7 7 7 5 0 11 12 14 27 2 16 21 84 41 153 21 69 38 139 39 156 1 18 6 29 11
26 5 -3 7 5 4 16 -4 12 -3 20 2 17 4 -3 10 10 13 28 3 17 7 41 10 52 19 72 26
118 21 127 -4 7 -2 8 4 4 8 -5 12 1 12 17 0 13 6 45 14 70 10 34 11 47 3 47
-9 0 -9 2 0 8 11 7 14 19 28 87 2 11 7 36 11 55 4 19 8 50 8 67 1 18 5 33 10
33 4 0 6 13 4 29 -2 17 -1 28 4 25 4 -3 8 7 9 23 0 15 2 35 4 43 6 27 14 74
20 115 12 78 16 99 20 104 2 2 0 13 -6 24 -8 14 -8 18 0 13 6 -4 11 0 12 11 0
10 2 27 3 38 2 11 6 49 11 85 4 36 9 74 11 85 2 11 7 52 10 90 3 39 8 72 10
74 2 2 -2 11 -9 19 -8 10 -8 13 -1 9 15 -9 23 79 9 95 -7 8 -6 9 3 4 9 -5 12
5 13 36 1 24 3 54 4 68 7 78 7 128 0 140 -5 9 -4 11 3 6 16 -10 15 16 0 33
-10 11 -10 13 0 7 9 -5 12 9 13 56 0 35 2 90 4 123 6 99 5 590 -1 745 -3 87
-10 151 -17 159 -9 12 -9 13 0 7 10 -5 12 2 9 31 -3 21 -8 74 -11 118 -8 110
-14 183 -20 230 -2 22 -7 72 -11 110 -3 39 -9 79 -11 90 -3 11 -5 27 -5 35 0
16 -25 198 -32 240 -3 14 -7 43 -11 65 -5 40 -14 92 -22 130 -2 11 -6 36 -8
55 -3 19 -8 46 -11 60 -3 14 -8 34 -10 45 -3 19 -6 32 -19 100 -3 17 -15 68
-26 115 -10 47 -21 94 -23 105 -3 11 -7 34 -11 50 -13 62 -66 265 -100 385
-20 69 -38 132 -40 140 -2 8 -4 16 -5 18 -2 1 -3 5 -4 10 -1 4 -13 41 -26 82
-28 90 -25 78 -35 115 -4 17 -10 35 -14 40 -3 6 -6 15 -7 20 -5 24 -116 331
-164 450 -74 187 -202 479 -213 486 -5 3 -8 9 -6 13 5 15 -205 424 -215 419
-5 -3 -6 -1 -3 4 9 14 -147 293 -304 545 -63 101 -241 371 -249 378 -4 3 -22
28 -40 55 -143 212 -480 627 -710 875 -147 159 -213 225 -223 222 -5 -1 -6 1
-3 6 6 10 -235 242 -418 403 -21 19 -44 39 -50 44 -105 95 -323 271 -506 407
-594 443 -1262 815 -1946 1083 -166 66 -545 195 -694 238 -100 29 -222 62
-265 73 -16 4 -34 8 -40 10 -80 20 -234 55 -259 59 -17 3 -39 8 -48 11 -9 3
-27 7 -40 9 -59 12 -237 45 -268 50 -19 3 -42 8 -50 10 -8 2 -40 7 -70 10 -30
3 -62 8 -70 10 -8 2 -40 7 -70 10 -30 3 -66 8 -80 10 -44 7 -103 14 -170 21
-36 3 -73 8 -82 9 -9 2 -68 7 -130 10 -62 4 -124 8 -138 11 -14 2 -124 6 -245
10 -121 3 -235 7 -252 8 -18 2 -34 2 -35 1z"
        />
        <path
          d="M17428 10515 c-2 -1 -41 -6 -88 -9 -173 -14 -287 -31 -433 -62 -39
-8 -81 -17 -92 -20 -11 -2 -67 -17 -125 -34 -58 -17 -113 -32 -123 -35 -24 -6
-213 -74 -262 -95 -22 -9 -55 -23 -72 -30 -18 -7 -30 -17 -26 -23 3 -6 1 -7
-5 -3 -15 9 -164 -63 -155 -77 3 -5 1 -7 -5 -3 -13 8 -41 -4 -107 -44 -27 -16
-76 -45 -107 -63 -32 -17 -56 -37 -53 -44 2 -7 -2 -10 -10 -7 -12 5 -54 -20
-120 -69 -5 -4 -32 -24 -60 -44 -64 -47 -72 -54 -96 -75 -10 -10 -23 -18 -27
-18 -5 0 -18 -10 -29 -22 -11 -13 -40 -39 -64 -58 -94 -76 -271 -258 -264
-271 5 -7 4 -10 -3 -5 -14 9 -84 -64 -77 -81 3 -8 0 -11 -6 -8 -12 8 -40 -26
-32 -39 4 -5 2 -6 -4 -3 -5 3 -26 -15 -46 -41 -19 -26 -45 -58 -56 -72 -46
-56 -104 -140 -96 -140 4 0 -3 -9 -16 -20 -13 -10 -20 -19 -16 -20 4 0 -2 -10
-13 -22 -11 -12 -18 -28 -15 -36 2 -7 0 -10 -5 -7 -6 3 -13 -2 -17 -12 -3 -10
-13 -26 -20 -35 -8 -9 -12 -22 -7 -29 4 -7 3 -10 -3 -6 -6 4 -19 -10 -29 -31
-10 -20 -23 -40 -27 -43 -4 -3 -9 -15 -10 -27 0 -12 -3 -19 -6 -17 -8 9 -62
-106 -55 -117 4 -6 3 -8 -3 -5 -13 8 -36 -40 -27 -56 4 -7 3 -9 -1 -5 -9 9
-86 -152 -78 -164 2 -5 0 -8 -5 -8 -5 0 -12 -10 -15 -22 -3 -13 -23 -74 -45
-135 -22 -62 -38 -113 -36 -113 3 0 0 -8 -5 -18 -6 -11 -12 -30 -15 -43 -3
-13 -14 -58 -25 -99 -11 -41 -22 -86 -25 -100 -3 -14 -7 -36 -10 -50 -24 -107
-24 -112 -12 -129 8 -11 8 -13 -1 -7 -7 4 -13 4 -14 -1 0 -4 -2 -17 -5 -28 -2
-11 -5 -44 -6 -72 -2 -29 -5 -53 -8 -53 -3 0 -6 -12 -6 -27 -1 -16 -4 -77 -9
-136 -11 -163 -13 -260 -4 -278 5 -9 5 -20 1 -23 -10 -11 -8 -40 3 -48 6 -5 5
-8 -3 -8 -7 0 -11 -3 -9 -7 3 -5 8 -57 11 -118 4 -60 9 -121 11 -135 2 -14 7
-50 11 -80 3 -30 7 -57 9 -60 2 -3 6 -27 9 -55 9 -68 22 -140 26 -140 1 0 4
-13 6 -30 3 -16 11 -36 17 -44 7 -9 8 -12 1 -8 -6 3 0 -31 13 -76 14 -45 26
-86 27 -92 1 -5 4 -14 7 -20 4 -5 9 -27 13 -48 4 -21 11 -35 15 -33 4 3 7 -5
6 -17 -1 -12 0 -26 4 -32 3 -5 6 -12 7 -15 0 -3 4 -14 9 -25 4 -11 17 -42 28
-70 10 -27 24 -61 30 -75 5 -14 11 -30 12 -36 2 -5 14 -36 29 -67 14 -31 26
-60 27 -65 0 -4 12 -22 27 -41 14 -19 21 -31 15 -28 -11 7 26 -73 38 -83 4 -3
14 -21 23 -40 32 -72 129 -223 138 -215 2 3 5 -1 5 -8 0 -23 105 -167 116
-160 6 3 7 1 3 -6 -8 -12 57 -93 68 -85 3 2 4 1 1 -3 -4 -5 32 -60 60 -89 5
-6 37 -40 69 -77 33 -36 63 -64 68 -61 5 3 7 0 6 -8 -2 -7 3 -12 10 -10 8 1
11 -1 8 -6 -7 -11 181 -188 195 -184 6 1 8 -2 5 -7 -4 -5 0 -13 7 -18 8 -4 36
-26 62 -48 26 -22 55 -47 65 -55 10 -8 28 -22 40 -30 13 -8 35 -24 49 -36 42
-34 115 -81 125 -82 5 0 12 -5 15 -10 12 -19 122 -83 131 -77 6 4 9 1 8 -5 -4
-15 80 -62 94 -54 6 4 8 3 5 -3 -8 -13 41 -36 55 -27 7 4 8 2 4 -4 -8 -13 121
-75 136 -66 6 4 8 3 5 -2 -3 -6 3 -16 15 -23 16 -10 19 -10 12 1 -4 8 0 7 12
-3 27 -22 114 -61 141 -63 12 0 19 -3 17 -5 -9 -10 185 -73 199 -64 6 3 7 1 3
-5 -4 -7 -1 -12 6 -13 22 -1 41 -6 92 -23 27 -9 56 -14 63 -12 8 3 12 2 9 -2
-3 -5 23 -14 57 -22 34 -7 70 -17 81 -22 12 -7 16 -7 12 0 -3 5 13 3 35 -5 23
-7 64 -16 93 -18 28 -2 51 -4 51 -5 0 -5 123 -23 165 -24 19 -1 38 -5 42 -11
5 -6 8 -5 8 3 0 8 5 10 12 5 7 -4 33 -9 58 -11 80 -6 450 -9 450 -3 0 3 36 6
80 6 44 0 80 3 80 6 0 3 10 5 23 4 12 -2 47 1 77 6 30 5 67 10 83 11 15 1 27
5 27 8 0 3 24 6 52 8 29 2 55 6 58 10 3 4 14 8 25 9 11 0 31 4 45 7 14 4 43 9
65 12 22 4 42 10 43 15 2 5 7 7 11 5 8 -5 163 39 241 68 25 9 52 18 60 20 37
10 136 50 141 58 3 5 9 9 13 7 11 -4 76 24 76 33 0 4 7 5 15 2 8 -4 15 -1 15
5 0 6 3 10 8 10 15 -3 91 34 86 42 -3 5 2 6 10 3 9 -3 16 -1 16 6 0 6 4 9 9 5
5 -3 12 -1 16 5 4 6 11 8 16 5 5 -4 9 -2 9 3 0 6 17 17 38 26 44 19 74 37 84
51 4 6 8 7 8 3 0 -4 16 4 36 17 20 14 41 25 46 25 5 0 6 5 2 12 -4 6 -3 8 3 5
13 -8 105 52 98 63 -3 4 5 6 17 2 16 -4 20 -2 16 9 -4 10 1 15 15 16 11 1 23
6 26 10 3 4 28 24 54 43 27 19 62 47 78 62 16 15 29 25 29 23 0 -6 50 35 148
122 106 95 163 154 157 164 -3 5 2 6 11 2 12 -4 15 -3 10 5 -4 7 -1 12 8 12 9
0 16 3 16 8 0 4 25 33 55 65 32 34 52 64 48 70 -3 7 -3 9 1 5 10 -8 30 11 76
72 19 25 39 50 45 56 5 7 19 24 30 39 11 16 30 41 42 58 12 16 20 34 17 39 -3
5 -2 7 3 6 4 -2 9 0 11 5 1 4 10 16 20 26 34 38 41 49 35 55 -4 3 -1 6 5 6 7
0 12 7 12 15 0 8 5 15 11 15 5 0 7 5 4 10 -3 6 -2 10 2 10 5 0 17 17 28 38 11
20 22 39 25 42 24 24 171 323 166 337 -3 8 -2 12 2 9 9 -5 55 97 55 121 0 7 4
13 8 13 4 0 10 12 15 28 10 35 9 32 22 63 27 67 83 262 78 275 -4 10 -2 12 6
8 7 -5 9 0 6 14 -4 12 -3 20 1 17 6 -3 21 54 37 140 1 6 7 35 13 65 6 30 14
73 17 95 3 22 8 42 10 44 2 2 -2 11 -9 19 -9 13 -9 14 1 8 8 -5 12 2 13 26 0
18 2 42 4 53 13 66 20 220 20 436 -1 201 -4 250 -15 265 -11 13 -12 16 -1 10
10 -6 12 -4 7 10 -3 11 -8 46 -11 79 -3 33 -8 71 -10 85 -3 14 -7 41 -10 60
-5 35 -11 65 -21 110 -3 14 -7 39 -10 55 -3 17 -8 37 -11 45 -3 8 -7 28 -9 44
-3 16 -14 61 -26 100 -12 39 -23 77 -24 84 -5 24 -64 200 -81 238 -9 22 -17
41 -18 44 -2 6 -17 43 -47 113 -58 138 -257 495 -280 505 -4 2 -8 8 -8 13 0
10 -177 249 -225 304 -135 155 -229 252 -334 345 -35 30 -74 65 -87 78 -45 40
-216 167 -314 232 -121 80 -266 162 -400 227 -114 55 -380 163 -402 163 -8 0
-19 5 -26 12 -7 7 -12 9 -12 5 0 -3 -19 1 -42 9 -24 9 -50 17 -58 19 -8 2 -51
13 -95 25 -44 11 -89 22 -100 25 -11 2 -31 7 -45 10 -32 7 -54 11 -175 31 -57
9 -80 12 -135 18 -30 3 -80 8 -110 12 -52 6 -467 14 -472 9z"
        />
      </g>
    </svg>
  );
};

ThemeSix.propTypes = {
  iconColorClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.string,
};

export default ThemeSix;
