import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const DesktopArrow = ({ activeDisplay }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.854"
      height="7.427"
      viewBox="0 0 12.854 7.427"
      className={activeDisplay ? "arrow-up" : "arrow-down"}
    >
      <path
        id="arrow"
        d="M325.844,1057.273l-5.013,5.013-5.013-5.013"
        transform="translate(-314.405 -1055.858)"
        fill="none"
        stroke="#00a0cc"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
};

DesktopArrow.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fillColorClassName: PropTypes.string,
  activeDisplay: PropTypes.bool,
};

export default DesktopArrow;
