import React from "react";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import ThemeOne from "../../shared/CourseIcons/ThemeOne";
import ThemeTwo from "../../shared/CourseIcons/ThemeTwo";
import ThemeThree from "../../shared/CourseIcons/ThemeThree";
import ThemeFour from "../../shared/CourseIcons/ThemeFour";
import ThemeFive from "../../shared/CourseIcons/ThemeFive";
import ThemeSix from "../../shared/CourseIcons/ThemeSix";
import PropTypes from "prop-types";

const Checkbox = ({
  checked,
  onChange,
  disabled,
  type,
  checkboxBorderColor,
  item,
}) => {
  const renderIcon = (name, disabled) => {
    let iconColorClass =
      disabled === true ? "disabled-svg-fill" : "path-primary-fill";
    switch (name) {
      case "Target":
        return (
          <ThemeOne
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      case "Face":
        return (
          <ThemeSix
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      case "Money":
        return (
          <ThemeThree
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      case "Scale":
        return (
          <ThemeFour
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      case "Bolt":
        return (
          <ThemeFive
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      case "Flag":
        return (
          <ThemeTwo
            width={"24px"}
            height={"24px"}
            iconColorClassName={iconColorClass}
          />
        );
      default:
        break;
    }
  };

  return (
    <ul
      css={css`
        li,
        p {
          font-family: ${theme.fontFaces.headers};
        }
        li {
          position: relative;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 23px;
          p {
            color: ${theme.colors.main};
          }
          svg {
            margin-right: 0.5em;
          }
        }
        li > label {
          font-size: 13px;
          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            font-size: 12px;
          }
        }

        input[type="checkbox"] {
          opacity: 0;
          cursor: pointer;
          margin-right: 1.5em;
        }
        label > span {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 24px;
          width: 24px;
          background-color: transparent;
          border-radius: 5px;
          border: 2px solid #b4b4b4;
          transition: all 0.3s ease-out;
          -webkit-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
        }
        label span:after {
          position: absolute;
          content: "";
          left: 12px;
          top: 12px;
          height: 0px;
          width: 0px;
          border-radius: 5px;
          border-width: 2px;
          border-style: solid;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(0deg) scale(0);
          -ms-transform: rotate(0deg) scale(0);
          transform: rotate(0deg) scale(0);
          -webkit-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          opacity: 1;
        }
        label input:checked ~ span {
          border-radius: 5px;
          -webkit-transform: rotate(0deg) scale(1);
          -ms-transform: rotate(0deg) scale(1);
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border-width: 2px;
          border-style: solid;
        }

        label input:checked ~ span:after {
          -webkit-transform: rotate(45deg) scale(1);
          -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 8px;
          top: 3px;
          width: 6px;
          height: 12px;
          border-width: 2px;
          border-style: solid;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
        label input:disabled ~ span:after,
        li.disabled-text p {
          color: ${theme.colors.accent};
        }
      `}
    >
      <li className={disabled ? "disabled-text" : "main"}>
        <label className={type || "" === "survey" ? "dark-gray-text" : ""}>
          <input
            name={item.name || item.label}
            checked={checked}
            type="checkbox"
            onChange={e => onChange(e)}
            data-testId="checkbox"
            disabled={disabled}
          />
          <span
            className={`
              ${
                checkboxBorderColor
                  ? checked
                    ? checkboxBorderColor
                    : theme.colors.black
                  : checked
                    ? "primary-checkbox-border-override"
                    : theme.colors.black
              }`}
          ></span>
          {item.icon && renderIcon(item.icon.icon, disabled)}
          {item.label}
          {item.description && <p>{item.description.description}</p>}
        </label>
      </li>
    </ul>
  );
};

Checkbox.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.shape({
      icon: PropTypes.string,
    }),
    description: PropTypes.shape({
      description: PropTypes.string,
    }),

    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    checkboxBorderColor: PropTypes.string,
    type: PropTypes.string,
  }),
};
export default Checkbox;
