import React from "react";
import PropTypes from "prop-types";

const TextAreaField = ({
  name,
  styles,
  placeholder,
  required,
  handleChange,
}) => (
  <div style={styles.wrapperStyles}>
    <label style={styles.labelStyles}>{placeholder}</label>
    <textarea
      type="text"
      name={name}
      placeholder={placeholder}
      required={required}
      style={styles.textAreaStyles}
      onChange={handleChange}
    />
  </div>
);

TextAreaField.propTypes = {
  name: PropTypes.string,
  styles: PropTypes.objectOf({
    wrapperStyles: PropTypes.object,
    labelStyles: PropTypes.object,
    valueStyles: PropTypes.object,
  }),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default TextAreaField;
