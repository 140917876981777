import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { theme } from "../../utils/global-styles/theme";

const PageBackground = ({ className, children, fullDeviceWidth = false }) => {
  const Container = styled.div`
    width: 100%;
    justify-self: center;
  `;
  return (
    <div className={className}>
      <Container>{children}</Container>
    </div>
  );
};

PageBackground.propTypes = {
  className: PropTypes.string,
};

export default PageBackground;
