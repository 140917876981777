import React, { useRef, useEffect } from "react";

const BurgerMenu = ({
  navToggleRef,
  barsRef,
  setMenuDropdownActive,
  toggleHamburger,
  hamburgerMenuRef,
}) => {
  const handleClick = () => {
    setMenuDropdownActive(prevState => !prevState);
    toggleHamburger();
    const menuContainer = hamburgerMenuRef?.current;
    if (menuContainer) {
      menuContainer?.classList?.add("show-mobile-menu");
    }
  };
  return (
    <a ref={navToggleRef} className="nav-toggle" onClick={() => handleClick()}>
      <span ref={el => (barsRef.current[0] = el)} className="bar"></span>
      <span ref={el => (barsRef.current[1] = el)} className="bar"></span>
      <span ref={el => (barsRef.current[2] = el)} className="bar"></span>
    </a>
  );
};

export default BurgerMenu;
