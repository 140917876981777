import { graphql, useStaticQuery } from "gatsby";

const useNotificationBarData = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulNotificationBar {
        displayText
        cta {
          linkText
          url
        }
      }
    }
  `);

  return data.contentfulNotificationBar;
};

export default useNotificationBarData;
