import { graphql, useStaticQuery } from "gatsby";
const useHelpData = () => {
  const data = useStaticQuery(graphql`
    query {
      help: allContentfulPageLayout(filter: {queryIdentifier: {eq: "help-comp"}}) {
        nodes {
          heading
          contentModules {
            ... on ContentfulLink {
              linkText
              url
            }
          }
        }
      }
    }
    
  `);
  return data.help.nodes[0];
};
export default useHelpData;
