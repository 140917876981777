import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

const cancelX = ({ width, height, fillColor, handleClick, styles }) => {
  return (
    <svg
      width={width || "12px"}
      height={height || "12px"}
      viewBox="0 0 12 12"
      onClick={handleClick}
      css={css`
        &:hover {
          cursor: pointer;
        }
        ${styles}
      `}
    >
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Course-Catalog-Search-1.0"
          transform="translate(-1305.000000, -220.000000)"
        >
          <g id="Group" transform="translate(0.000000, 173.000000)">
            <g id="Group-22" transform="translate(119.000000, 42.000000)">
              <g
                id="icon/close/black"
                transform="translate(1182.000000, 1.000000)"
              >
                <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                <g
                  id="Group"
                  strokeWidth="1"
                  fillRule="evenodd"
                  transform="translate(3.000000, 3.000000)"
                  fill={fillColor || theme.colors.main}
                >
                  <path
                    d="M0.288461538,6.21153846 L13.7884615,5.71153846 C14.3210218,5.69181401 14.7687371,6.1075496 14.7884615,6.64010989 C14.7893431,6.66391125 14.7893431,6.6877371 14.7884615,6.71153846 C14.7667745,7.29708841 14.2970884,7.7667745 13.7115385,7.78846154 L0.211538462,8.28846154 C-0.321021833,8.30818599 -0.768737083,7.8924504 -0.788461538,7.35989011 C-0.78934307,7.33608875 -0.78934307,7.3122629 -0.788461538,7.28846154 C-0.766774503,6.70291159 -0.297088411,6.2332255 0.288461538,6.21153846 Z"
                    id="Rectangle"
                    transform="translate(7.000000, 7.000000) rotate(-315.000000) translate(-7.000000, -7.000000) "
                  ></path>
                  <path
                    d="M0.211538462,5.71153846 L13.7115385,6.21153846 C14.2970884,6.2332255 14.7667745,6.70291159 14.7884615,7.28846154 C14.808186,7.82102183 14.3924504,8.26873708 13.8598901,8.28846154 C13.8360887,8.28934307 13.8122629,8.28934307 13.7884615,8.28846154 L0.288461538,7.78846154 C-0.297088411,7.7667745 -0.766774503,7.29708841 -0.788461538,6.71153846 C-0.808185994,6.17897817 -0.392450404,5.73126292 0.14010989,5.71153846 C0.163911254,5.71065693 0.187737097,5.71065693 0.211538462,5.71153846 Z"
                    id="Rectangle"
                    transform="translate(7.000000, 7.000000) rotate(-225.000000) translate(-7.000000, -7.000000) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

cancelX.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  handleClick: PropTypes.func,
};

export default cancelX;
