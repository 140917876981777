import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const DownArrow = ({ activeDisplay }) => {
  return (
    <svg
      width={"13px"}
      height={"8px"}
      viewBox="0 0 13 8"
      version="1.1"
      className={activeDisplay ? "arrow-up" : "arrow-down"}
    >
      <g
        id="Icon/Caret-in-Navigation/South/Light-Blue"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M3.31093394,-1.68430384 C2.89635535,-1.26518418 2.89635535,-0.588144729 3.31093394,-0.16902507 L7.43545938,4.00067821 L3.31093394,8.17038149 C2.89635535,8.58950115 2.89635535,9.2665406 3.31093394,9.68566026 C3.72551253,10.1047799 4.3952164,10.1047799 4.80979499,9.68566026 L9.68906606,4.75294426 C10.1036446,4.3338246 10.1036446,3.65678515 9.68906606,3.2376655 L4.80979499,-1.6950505 C4.40584662,-2.1034235 3.72551253,-2.1034235 3.31093394,-1.68430384 Z"
          id="Path"
          className={"white-fill"}
          fillRule="nonzero"
          transform="translate(6.500000, 4.000000) rotate(90.000000) translate(-6.500000, -4.000000) "
        ></path>
      </g>
    </svg>
  );
};

DownArrow.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fillColorClassName: PropTypes.string,
  activeDisplay: PropTypes.bool,
};

export default DownArrow;
