import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import "./index.css";
import { theme } from "../../../utils/global-styles/theme";

const AcumenAcademyScalableLogo = ({ containerRef, animatedPartOfLogoRef }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    transition: height 500ms ease;
  `;

  const StaticPartOfLogo = styled.div`
    position: relative;
    width: 51px;

    @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
      width: unset;
    }
  `;

  const AnimatedPartOfLogo = styled.div`
    position: relative;
    transition: transform 500ms ease-in-out;
    transform-origin: top left;
    transform: translateY(-94%) scale(5.2);
    width: 51px;

    @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
      left: 0px;
      transform: translateY(-500%) scale(22.2);
    }

    @media (min-width: 700px) and (max-width: ${theme.breakpoints
        .iPadPro}) {
      transform: translateY(-264%) scale(12.2);
    }
  `;

  return (
    <Container ref={containerRef}>
      <StaticPartOfLogo>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"50px"}
          viewBox="0 0 58.758 60.074"
        >
          <g
            id="Group_119"
            data-name="Group 119"
            transform="translate(-79.281 -25)"
          >
            <path
              id="Path_863"
              data-name="Path 863"
              d="M62.848,17.738l-1.328-3.5-1.36,3.5Zm.766,1.985H59.394l-.7,1.829H56.238l4.344-10.939h1.875l4.359,10.939H64.3Z"
              transform="translate(23.043 63.365)"
              fill="#03364f"
            />
            <path
              id="Path_864"
              data-name="Path 864"
              d="M68.59,18.206a3.908,3.908,0,0,1,3.985-4,3.653,3.653,0,0,1,3.75,3.078H74.09a1.513,1.513,0,0,0-1.515-.968,1.884,1.884,0,0,0,0,3.766,1.54,1.54,0,0,0,1.531-1.032H76.34a3.624,3.624,0,0,1-3.766,3.141,3.9,3.9,0,0,1-3.985-3.985"
              transform="translate(21.382 62.882)"
              fill="#03364f"
            />
            <path
              id="Path_865"
              data-name="Path 865"
              d="M86.411,14.387v7.672h-2.2V21.043a2.365,2.365,0,0,1-2.078,1.172c-1.969,0-3.156-1.328-3.156-3.5V14.387h2.2v4.047a1.523,1.523,0,1,0,3.031,0V14.387Z"
              transform="translate(19.984 62.858)"
              fill="#03364f"
            />
            <path
              id="Path_866"
              data-name="Path 866"
              d="M101.372,17.269v4.766H99.153v-4.25a1.3,1.3,0,0,0-1.312-1.469,1.349,1.349,0,0,0-1.391,1.469v4.25h-2.2v-4.25a1.3,1.3,0,0,0-1.328-1.469,1.326,1.326,0,0,0-1.359,1.469v4.25h-2.2V14.363h2.2v.922a2.58,2.58,0,0,1,2.188-1.078,2.271,2.271,0,0,1,2.171,1.125,2.85,2.85,0,0,1,2.313-1.125,2.949,2.949,0,0,1,3.141,3.063"
              transform="translate(18.588 62.882)"
              fill="#03364f"
            />
            <path
              id="Path_867"
              data-name="Path 867"
              d="M110.12,17.316a1.515,1.515,0,0,0-1.579-1.328,1.719,1.719,0,0,0-1.766,1.328Zm2.25,1.406h-5.7a1.882,1.882,0,0,0,1.954,1.641c.859,0,1.406-.376,1.531-.906h2.157a3.381,3.381,0,0,1-3.625,2.734,3.983,3.983,0,0,1-4.032-4.03,3.917,3.917,0,0,1,3.985-3.954,3.735,3.735,0,0,1,3.781,3.734,7.684,7.684,0,0,1-.047.782"
              transform="translate(16.53 62.882)"
              fill="#03364f"
            />
            <path
              id="Path_868"
              data-name="Path 868"
              d="M122.489,17.69v4.345h-2.2V17.972a1.522,1.522,0,1,0-3.032,0v4.063h-2.2V14.363h2.2v1a2.425,2.425,0,0,1,2.078-1.156c1.97,0,3.156,1.313,3.156,3.484"
              transform="translate(15.131 62.882)"
              fill="#03364f"
            />
          </g>
          <path
            id="Path_876"
            data-name="Path 876"
            d="M52.119,46.55H46.408L29,.021H52.119Z"
            transform="translate(6.639 0.005)"
            fill="#00a0cc"
          />
          <path
            id="Path_878"
            data-name="Path 878"
            d="M11.618.022H0V38.466H4.638L16.008,8.08h-4.39Z"
            transform="translate(0.565 0.005)"
            fill="#00a0cc"
          />
          <path
            id="Path_879"
            data-name="Path 879"
            d="M17.64,8.084V0H11.618V8.084h4.389L4.637,38.471H0v8.083H6.238V38.471h5.329L22.938,8.084Z"
            transform="translate(0.565 0)"
            fill="#03364f"
          />
          <path
            id="Path_880"
            data-name="Path 880"
            d="M21.879,32.47H37.017l-3.033-8.084H24.9l4.52-12.08L24.882,0H16.56V8.084h5.3L10.487,38.471H5.158v8.084H24.882V38.471H19.633Z"
            transform="translate(1.645 0)"
            fill="#00a0cc"
          />
        </svg>
      </StaticPartOfLogo>
      <AnimatedPartOfLogo ref={animatedPartOfLogoRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 295 58.898"
          width="100%"
          height="100%"
        >
          <g
            id="Group_118"
            data-name="Group 118"
            transform="translate(-79.281 -86.728)"
          >
            <path
              id="Path_869"
              data-name="Path 869"
              d="M85.105,56.464l-5.8-15.286L73.369,56.464Zm3.345,8.667H70.023l-3.069,7.987H56.238L75.209,25.344H83.4L102.44,73.118H91.451Z"
              transform="translate(23.043 61.384)"
              fill="#03364f"
            />
            <path
              id="Path_870"
              data-name="Path 870"
              d="M68.59,46.408a17.069,17.069,0,0,1,17.4-17.471c8.4,0,15.494,5.734,16.378,13.445h-9.76c-.68-2.457-3.409-4.23-6.619-4.23a7.892,7.892,0,0,0-7.983,8.255A7.915,7.915,0,0,0,85.993,54.6c3.209,0,6.006-1.909,6.687-4.506h9.76c-.748,7.987-7.915,13.717-16.446,13.717a17.013,17.013,0,0,1-17.4-17.4"
              transform="translate(57.383 71.372)"
              fill="#03364f"
            />
            <path
              id="Path_871"
              data-name="Path 871"
              d="M104.619,46.34a8.394,8.394,0,1,0-8.4,8.259,8.231,8.231,0,0,0,8.4-8.259m9.624-16.719V63.13h-9.624V58.352A12.17,12.17,0,0,1,93.971,63.81c-8.667,0-15.558-7.847-15.558-17.471s6.891-17.4,15.558-17.4A12.17,12.17,0,0,1,104.619,34.4V29.621Z"
              transform="translate(84.687 71.372)"
              fill="#03364f"
            />
            <path
              id="Path_872"
              data-name="Path 872"
              d="M115.508,56.324a8.231,8.231,0,0,0-8.4-8.255,8.26,8.26,0,1,0,0,16.518,8.191,8.191,0,0,0,8.4-8.263m9.624-30.98v47.77h-9.624V68.337A12.173,12.173,0,0,1,104.86,73.8C96.193,73.8,89.3,65.951,89.3,56.324s6.891-17.4,15.558-17.4a12.17,12.17,0,0,1,10.648,5.458V25.344Z"
              transform="translate(114.958 61.384)"
              fill="#03364f"
            />
            <path
              id="Path_873"
              data-name="Path 873"
              d="M123.985,42.518c-.272-3.413-3.345-5.8-6.895-5.8a7.508,7.508,0,0,0-7.711,5.8Zm9.828,6.142H108.9a8.219,8.219,0,0,0,8.531,7.167c3.753,0,6.142-1.641,6.687-3.958h9.419C132.448,59.1,126.1,63.81,117.707,63.81A17.391,17.391,0,0,1,100.1,46.2a17.1,17.1,0,0,1,17.4-17.267,16.312,16.312,0,0,1,16.514,16.31c0,.752-.072,2.321-.2,3.413"
              transform="translate(144.977 71.372)"
              fill="#03364f"
            />
            <path
              id="Path_874"
              data-name="Path 874"
              d="M162.982,42.314V63.13H153.29V44.567c0-3.958-2.253-6.415-5.734-6.415-3.205,0-6.071,2.113-6.071,6.415V63.13h-9.624V44.567c0-3.958-2.253-6.415-5.8-6.415-3.141,0-5.938,2.045-5.938,6.415V63.13H110.5V29.621h9.62v4.026a11.272,11.272,0,0,1,9.556-4.71c4.366,0,7.439,1.433,9.488,4.914a12.438,12.438,0,0,1,10.1-4.914c7.987,0,13.721,5.258,13.721,13.377"
              transform="translate(173.894 71.372)"
              fill="#03364f"
            />
            <path
              id="Path_875"
              data-name="Path 875"
              d="M160.48,29.118,141.849,73.751h-10.3l6.21-13.853-12.9-30.78H135.23l7.439,19.587L150.1,29.118Z"
              transform="translate(213.801 71.875)"
              fill="#03364f"
            />
          </g>
        </svg>
      </AnimatedPartOfLogo>
    </Container>
  );
};

AcumenAcademyScalableLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default AcumenAcademyScalableLogo;
