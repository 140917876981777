import { _post } from "./api.service";
import lodashGet from "lodash/get";
import ThemeOne from "../../components/shared/CourseIcons/ThemeOne";
import ThemeTwo from "../../components/shared/CourseIcons/ThemeTwo";
import ThemeThree from "../../components/shared/CourseIcons/ThemeThree";
import ThemeFour from "../../components/shared/CourseIcons/ThemeFour";
import ThemeFive from "../../components/shared/CourseIcons/ThemeFive";
import ThemeSix from "../../components/shared/CourseIcons/ThemeSix";

import Cookies from "universal-cookie";
/**
 *
 * @param {
 * String
 * oneOf ["Format", "Topic", "Payment", "Availability", "Order"]
 * } filterCriteria
 * @param { String } filterValue
 * @param { ArrayOf Course } courses
 * @param { ArrayOf String } checkedItems corresponds to checkbox filter values
 * @param { Function } sortCourses chronological/alphanumeric sorting method
 * @param { Function } setFilteredCourses setState method to update courses after they'be been filtered
 * @param { String } sortTitleValue object property to refer to the title of the course to be filtered by
 * @param { String } sortDateValue object property to refer to the start date of the course to be filtered by
 * @param { Number } indexToSliceBy number of courses that we want to limit the load to; dictated by pagination
 */
const cookies = new Cookies(),
  userDetailsCookie = "lck_aa";

const filterAllCourses = (
  filterCriteria,
  filterValue,
  courses,
  checkedItems,
  sortCourses,
  setFilteredCourses,
  sortTitleValue,
  sortDateValue,
  indexToSliceBy
) => {
  // Make a copy of the courses array from Contentful to be used for filtering
  let filteredCourses = courses.map(course => Object.assign({}, course));
  switch (filterCriteria) {
    case "Format":
      if (Object.values(checkedItems).filter(Boolean).length) {
        filteredCourses = courses.filter(course =>
          Object.entries(checkedItems).find(
            format =>
              format.includes(true) &&
              format[0].toUpperCase().includes(course.courseType.toUpperCase())
          )
        );
      }
      break;
    case "Topics":
      if (Object.values(checkedItems).filter(Boolean).length) {
        filteredCourses = courses.filter(course =>
          Object.entries(checkedItems).find(
            topic =>
              topic.includes(true) &&
              topic[0].toUpperCase().includes(course.theme.theme.toUpperCase())
          )
        );
      }
      break;
    case "Payment":
      if (filterValue === "All") {
        filteredCourses = courses;
        break;
      }
      filteredCourses = courses.filter(course =>
        filterValue.toLowerCase() === "free"
          ? course.courseCost.toLowerCase() === filterValue.toLowerCase()
          : course.courseCost.toLowerCase() !== "free"
      );
      break;
    case "Availability":
      if (filterValue === "All") {
        filteredCourses = courses;
        break;
      }
      filteredCourses = courses.filter(course =>
        filterValue.toLowerCase() === "on demand"
          ? course.courseAvailability.toLowerCase() ===
            filterValue.toLowerCase()
          : course.courseAvailability.toLowerCase() !== "on demand"
      );
      break;
    default:
      sortCourses(checkedItems, filteredCourses, sortTitleValue, sortDateValue);
      break;
  }
  setFilteredCourses(filteredCourses.slice(0, indexToSliceBy));
};

/**
 *
 * @param { String } sortBy Name of the value to sort by
 * @param { ArrayOf Course } courses
 * @param { String } sortTitleValue object property to refer to the title of the course to be filtered by
 * @param { String } sortDateValue object property to refer to the start date of the course to be filtered by
 */
const sortCourses = (sortBy, courses, sortTitleValue, sortDateValue) => {
  if (sortBy.toUpperCase().includes("(A-Z)")) {
    courses = courses.sort((a, b) => {
      const titleA = a[sortTitleValue].toUpperCase(),
        titleB = b[sortTitleValue].toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  } else if (sortBy.toUpperCase().includes("(Z-A)")) {
    courses = courses.sort((a, b) => {
      const titleA = a[sortTitleValue].toUpperCase(),
        titleB = b[sortTitleValue].toUpperCase();
      if (titleA < titleB) {
        return 1;
      }
      if (titleA > titleB) {
        return -1;
      }
      return 0;
    });
  } else if (sortBy.toUpperCase().includes("Date")) {
    courses = courses.sort((a, b) => {
      return new Date(b[sortDateValue]) - new Date(a[sortDateValue]);
    });
  } //TODO: Ryeker: Add the last condition when we figure out that is (for "Upcoming" sort)
  return courses;
};

const determineTopicIcon = (
  iconIdentifier,
  classNames,
  styles,
  width,
  height
) => {
  switch (
    !isNaN(parseInt(iconIdentifier)) ? parseInt(iconIdentifier) : iconIdentifier
  ) {
    case 1:
    case "redefine success":
      return (
        <ThemeOne
          iconColorClassName={classNames || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
    case 2:
    case "imagine and develop your idea":
      return (
        <ThemeTwo
          iconColorClassName={classNames || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
    case 3:
    case "fund your idea":
      return (
        <ThemeThree
          iconColorClassName={classNames || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
    case 4:
    case "build your business":
      return (
        <ThemeFour
          iconColorClassName={classNames || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
    case 5:
    case "master innovation":
      return (
        <ThemeFive
          iconColorClassName={`${classNames}` || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
    default:
      return (
        <ThemeSix
          iconColorClassName={classNames || ""}
          styles={styles}
          width={width}
          height={height}
        />
      );
  }
};

/**
 *
 * @param { String } provider Name of the course provider (i.e. NovoEd or Thinkific) as specified in Contentful
 * @param { String } providerUrl Path to access the course in NovoEd or Thinkific as specified in Contentful
 */
const handleGoToCourseClick = async (provider, providerUrl) => {
  if (provider.toUpperCase() === "THINKIFIC") {
    const userDetails = getUserDetails(),
      { external_id, email, first_name, last_name } = userDetails;

    let thinkificToken = await _post({
      endpoint: "profile",
      args: {
        account: {
          external_id,
          email,
          first_name,
          last_name,
        },
      },
    });

    if (thinkificToken.success) {
      const currentLocation = window.location.href,
        { token } = thinkificToken.data,
        thinkificInstitutionName = process.env.GATSBY_thinkificInstitution,
        returnToUrl = `https://${thinkificInstitutionName}.thinkific.com/courses/take/${providerUrl}`;

      return (window.location.href = `https://${thinkificInstitutionName}.thinkific.com/api/sso/v2/sso/jwt?jwt=${token}&return_to=${returnToUrl}&error_url=${currentLocation}`);
    }
  } else if (provider.toUpperCase() === "NOVOED") {
    const isLoggedIn = () => {
      const currentUser = getUserDetails();
      return currentUser && currentUser !== "undefined";
    };
    if (isLoggedIn()) {
      const currentUser = getUserDetails();
      if (currentUser.p_lo) {
        return window.location.replace(
          `${process.env.GATSBY_login_url}sso?course_id=${providerUrl}&resume=true`
        );
      }
    }

    const novoedInstitutionName = process.env.GATSBY_novoedInstitution;
    return (window.location.href = `https://${novoedInstitutionName}.novoed.com/#!/courses/${providerUrl}`);
  }
};

const singularize = plural => {
    return plural
      .split("es")[0]
      .toLowerCase()
      .includes("clas")
      ? "Master Class"
      : "Team Course";
  },
  isAcumenStaff = () => {
    const currentUser = getUserDetails();
    const emailDomain = currentUser?.email?.split("@")[1];
    const acceptedEmails = process.env.GATSBY_accepted_emails?.split(",");
    if (acceptedEmails?.includes(currentUser?.email)) return true;
    return emailDomain === "acumen.org" || emailDomain === "acumenacademy.org";
  },
  isLoggedIn = () => {
    const currentUser = getUserDetails();
    return currentUser && currentUser !== "undefined";
  },
  isValidEmail = value => {
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return emailRegex.test(value);
  },
  currentLocation = () => {
    if (typeof window !== "undefined") return window?.location?.pathname;
  },
  getUserDetails = () => {
    return cookies.get(userDetailsCookie)?.user;
  },
  saveUserDetails = details => {
    details = { user: details };
    cookies.set(userDetailsCookie, JSON.stringify(details));
  },
  removeUserDetails = () => {
    cookies.remove(userDetailsCookie);
  },
  isUserType = (usertype, user_details = null) => {
    if (user_details) {
      return lodashGet(user_details, "user_type", []).includes(usertype);
    }
    return lodashGet(getUserDetails(), "user_type", []).includes(usertype);
  },
  gotoSurveyMonkey = () => {
    const url = `${process.env.GATSBY_sm_login_url}`;
    if (typeof window === "object") {
      let language = navigator.language || navigator.userLanguage;
      language = language?.includes("es") ? "es" : "en";
      return (window.location.href = `${url}?lang=${language}`);
    }
    return false;
  },
  updateUserData = newUserDate => {
    let userDetails = getUserDetails();
    if (userDetails && userDetails?.email) {
      const {
        email,
        first_name,
        last_name,
        provider_id,
        user_type,
        external_id,
      } = newUserDate;

      userDetails.email = email;
      userDetails.first_name = first_name;
      userDetails.last_name = last_name;
      userDetails.provider_id = provider_id;
      userDetails.user_type = user_type;
      userDetails.external_id = external_id;
      saveUserDetails(userDetails);
    }
  },
  determineProductType = type => {
    if (
      type === "Master Class" ||
      type === "On-Demand" ||
      type === "Team Course" ||
      type === "Curso Diseñado Para Equipos"
    ) {
      return "course";
    } else {
      return type.toLowerCase();
    }
  },
  determineProductPath = (type, languageCode = "en-US", slug) => {
    return languageCode === "en-US"
      ? `/${type}/${slug}`
      : `/${type}/${languageCode}/${slug}`;
  };

export {
  filterAllCourses,
  sortCourses,
  determineTopicIcon,
  handleGoToCourseClick,
  singularize,
  isLoggedIn,
  getUserDetails,
  saveUserDetails,
  removeUserDetails,
  isUserType,
  gotoSurveyMonkey,
  updateUserData,
  determineProductType,
  determineProductPath,
  isValidEmail,
  currentLocation,
  isAcumenStaff,
};
