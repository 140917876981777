import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Global, css } from "@emotion/core";

import BurgerMenu from "./Icons/BurgerMenu.js";
import CloseMenu from "./Icons/CloseMenu.js";
import styled from "@emotion/styled";

const BurgerMenuConductor = ({
  isLoggedIn,
  headerLinks,
  footerLinks,
  setShowScalableLogo,
  showScalableLogo,
}) => {
  const [isMenuDropdownActive, setMenuDropdownActive] = useState(false);
  const navToggleRef = useRef(null);
  const barsRef = useRef([]);
  const hamburgerMenuRef = useRef();

  const toggleHamburger = () => {
    barsRef.current.forEach(bar => bar.classList.toggle("x"));
  };

  return (
    <>
      {isMenuDropdownActive && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <div
        className="trigger"
        css={css`
          cursor: pointer;
          position: relative;
          margin-top: 0.25em;
        `}
      >
        <BurgerMenu
          navToggleRef={navToggleRef}
          barsRef={barsRef}
          setMenuDropdownActive={setMenuDropdownActive}
          toggleHamburger={toggleHamburger}
          hamburgerMenuRef={hamburgerMenuRef}
        />
      </div>

      <CloseMenu
        isLoggedIn={isLoggedIn}
        headerLinks={headerLinks}
        footerLinks={footerLinks}
        setMenuDropdownActive={setMenuDropdownActive}
        isMenuDropdownActive={isMenuDropdownActive}
        setShowScalableLogo={setShowScalableLogo}
        toggleHamburger={toggleHamburger}
        hamburgerMenuRef={hamburgerMenuRef}
      />
    </>
  );
};

BurgerMenuConductor.propTypes = {
  isLoggedIn: PropTypes.bool,
  headerLinks: PropTypes.shape({
    loggedInLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    loggedOutLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    myDashboardLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
  footerLinks: PropTypes.shape({
    newsletterTitle: PropTypes.string,
    newsletterDescription: PropTypes.string,
    support: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    about: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    help: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    termsOfUse: PropTypes.objectOf(PropTypes.string),
    privacyPolicy: PropTypes.objectOf(PropTypes.string),
    facebook: PropTypes.objectOf(PropTypes.string),
    twitter: PropTypes.objectOf(PropTypes.string),
    instagram: PropTypes.objectOf(PropTypes.string),
  }),
};

export default BurgerMenuConductor;
