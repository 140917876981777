import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../utils/global-styles/theme";
import Styled from "@emotion/styled";
import Cancel from "../../Search/Icons/cancel-x";

const dayjs = require("dayjs");

const Banner = ({ verbiage, dismissText, type, setError, error }) => {
  const Banner = Styled.div`
        width: 80%;
        background-color: ${theme.colors.dkBlue};
        position: fixed;
        left: 10%;
        right: 10%;
        bottom: 20px;
        z-index: 10;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 40px 40px 40px 90px;
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 10px 20px 10px 20px;
            display: grid;
            grid-template-columns: 100%;
        }
    `,
    Verbiage = Styled.p`
        color: ${theme.colors.white};
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -0.45px;
        line-height: 24px;
        font-family: ${theme.fontFaces.headers};
        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
          font-size: 16px;
        }

        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          order: 2;
          width: 100%;
        }
    `,
    DismissText = Styled.div`
        font-size: 16px;
        font-weight: 500;
        line-height: 13px;
        text-transform: capitalize;
        padding-left: 5px;
        padding-right: 5px;
        color: ${theme.colors.white};
        font-family: ${theme.fontFaces.headers};

        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          padding-right: 8px;
        }
    `,
    DismissLink = Styled.div`
        display: flex;
        align-items: baseline;
        width: 100px;
        justify-content: space-evenly;
        transition: 0.2s ease-in-out all;
        &:hover {
            cursor: pointer;
        }

        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          justify-content: flex-end;
          width: 100%;
        }

    `,
    DismissLinkWrapper = Styled.div`
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      order: 1;
      width: 100%;
      margin-bottom: 20px;
    }
    `,
    [isBannerShowing, setIsBannerShowing] = useState(false);

  useEffect(() => {
    // We use dayjs to do the Date math. if it's been >= 90 days since last accepted,
    // we show the banner again.
    if (type !== "error") {
      const today = dayjs(),
        bannerLastDismissed = dayjs(localStorage.getItem("bannerDate"));
      setIsBannerShowing(
        localStorage.getItem("bannerDate")
          ? today.diff(bannerLastDismissed, "day") > 90
          : true
      );
    }
  }, []);

  useEffect(() => {
    if (type === "error") {
      setIsBannerShowing(true);
    }
  }, [type]);

  const dismissFunction = () => {
    localStorage.setItem("bannerDate", new Date());
    setIsBannerShowing(false);
  };

  return (
    <>
      {isBannerShowing ? (
        <Banner>
          <Verbiage>{verbiage}</Verbiage>
          <DismissLinkWrapper>
            <DismissLink
              onClick={type === "error" ? () => setError(false) : dismissFunction}
            >
              <DismissText>{dismissText || "Accept"}</DismissText>
              <Cancel
                width="12px"
                height="12px"
                fillColor={theme.colors.white}
              />
            </DismissLink>
          </DismissLinkWrapper>
        </Banner>
      ) : null}
    </>
  );
};

Banner.propTypes = {
  verbiage: PropTypes.element,
  dismissText: PropTypes.string,
};

export default Banner;
