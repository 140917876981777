import { graphql, useStaticQuery } from "gatsby";
const useHeaderFooterData = () => {
  return useStaticQuery(graphql`
    query {
      footer: contentfulFooter(node_locale: { eq: "en-US" }) {
        newsletterHeading
        newsLetterDescription {
          json
        }
        column1Heading
        column1Links {
          linkText
          url
        }
        column2Heading
        column2Links {
          linkText
          url
        }
        column3Heading
        column3Links {
          linkText
          url
        }
        column4Heading
        column4Links {
          linkText
          url
        }
        facebook {
          url
        }
        linkedin {
          url
        }
        twitter {
          url
        }
        youtube {
          url
        }
        termsOfUse {
          linkText
          url
        }
        privacyPolicy {
          linkText
          url
        }
        emailErrorMessage
        successMessage

      }
      header: contentfulGlobalHeader(node_locale: { eq: "en-US" }) {
        headerLinks {
          title
          url
          showSubLinks
          subLinks {
            linkText
            url
          }
        }
        myDashboardLinks {
          title
          url
          showSubLinks
          subLinks {
            linkText
            url
          }
        }
      }
      notifications: allContentfulNotifications(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        nodes {
          title
          datePublished
          url
        }
      }
    }
  `);
};
export default useHeaderFooterData;
