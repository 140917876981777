import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const logErrorToMyService = (error, details) => console.error(error); //TODO: add reporting tools
export class GlobalErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {
        response: {
          message: "",
        },
      },
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToMyService(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <h1 className="primary">
        {get(this.state, "error.response.message", this.state.error.toString())}
      </h1>
    ) : (
      this.props.children
    );
  }
}

GlobalErrorHandler.propTypes = {};
