import { _contentfulPost } from "../utils/services/api.service";

const SET_SEARCH_COURSES = "SET_SEARCH_COURSES",
  SET_FOOTER_HEIGHT = "SET_FOOTER_HEIGHT",
  SET_CHECKED_FELLOWSHIP_REGIONS = "SET_CHECKED_FELLOWSHIP_REGIONS",
  SET_ACTIVE_FORMATS = "SET_ACTIVE_FORMATS",
  SET_CHECKED_FELLOWSHIP_YEARS = "SET_CHECKED_FELLOWSHIP_YEARS",
  SET_FELLOWSHIP_SEARCH_NAME = "SET_FELLOWSHIP_SEARCH_NAME",
  SET_FELLOWS_SEARCH_RESULTS = "SET_FELLOWS_SEARCH_RESULTS",
  SET_FELLOWS_SEARCH_RESULTS_LIMIT = "SET_FELLOWS_SEARCH_RESULTS_LIMIT",
  SET_ALL_COURSES = "SET_ALL_COURSES",
  SET_FILTERS_ARE_DISABLED = "SET_FILTERS_ARE_DISABLED",
  SET_ACTIVE_TOPICS = "SET_ACTIVE_TOPICS",
  SET_ACTIVE_PAYMENT_TYPE = "SET_ACTIVE_PAYMENT_TYPE",
  SET_ACTIVE_AVAILABILITY_TYPE = "SET_ACTIVE_AVAILABILITY_TYPE",
  SET_PENDING_COURSE_ENROLLMENT_NOTIFICATION =
    "SET_PENDING_COURSE_ENROLLMENT_NOTIFICATION",
  SET_ACTIVE_TIMEFRAME = "SET_ACTIVE_TIMEFRAME",
  SET_ACTIVE_SORT_VALUE = "SET_ACTIVE_SORT_VALUE",
  SET_SEARCH_CRITERIA = "SET_SEARCH_CRITERIA";

export const setSearchCourses = () => ({
  type: SET_SEARCH_COURSES,
});

export const setSearchCriteria = matches => ({
  type: SET_SEARCH_CRITERIA,
  matches,
});

export const setAllCourses = allCourses => ({
  type: SET_ALL_COURSES,
  allCourses,
});

export const setFormats = activeFormats => ({
  type: SET_ACTIVE_FORMATS,
  activeFormats,
});

export const setTopics = activeTopics => ({
  type: SET_ACTIVE_TOPICS,
  activeTopics,
});

export const setPaymentType = activePaymentType => ({
  type: SET_ACTIVE_PAYMENT_TYPE,
  activePaymentType,
});

export const setActiveTimeframe = timeframe => ({
  type: SET_ACTIVE_TIMEFRAME,
  timeframe,
});

export const setAvailabilityType = activeAvailabilityType => ({
  type: SET_ACTIVE_AVAILABILITY_TYPE,
  activeAvailabilityType,
});

export const setSortValue = activeSortValue => ({
  type: SET_ACTIVE_SORT_VALUE,
  activeSortValue,
});

export const setupCourses = courses => {
  return (dispatch, getState) => {
    const { allCourses } = getState().courseReducer;
    let coursesToSetup = allCourses ? allCourses : courses,
      selectedFormats = {},
      selectedTopics = {};
    coursesToSetup.forEach(course => {
      selectedFormats[
        course.theme ? course.courseType : course.productType
      ] = true;
      selectedTopics[
        course.theme
          ? course.theme.theme.toLowerCase()
          : course.courseType.toLowerCase()
      ] = true;
    });
    dispatch(setFormats(selectedFormats));
    dispatch(setTopics(selectedTopics));
  };
};

export const setCheckedFellowshipRegions = checkedRegions => ({
  type: SET_CHECKED_FELLOWSHIP_REGIONS,
  checkedRegions,
});

export const setCheckedFellowshipYears = checkedYears => ({
  type: SET_CHECKED_FELLOWSHIP_YEARS,
  checkedYears,
});

export const setFellowshipSearchName = searchName => ({
  type: SET_FELLOWSHIP_SEARCH_NAME,
  searchName,
});

const setFellowsSearchResults = searchResults => ({
  type: SET_FELLOWS_SEARCH_RESULTS,
  searchResults,
});

export const setFellowsSearchResultsLimit = resultsLimit => ({
  type: SET_FELLOWS_SEARCH_RESULTS_LIMIT,
  resultsLimit,
});

export const getSearchResults = () => {
  return async (dispatch, getState) => {
    const {
      checkedFellowshipRegions,
      checkedFellowshipYears,
      fellowshipSearchResultsLimit,
      searchName,
    } = getState().fellowsReducer;

    const results = await _contentfulPost({
      query: `query($name: String, $region: [String], $year: [String], $limit: Int) {
        nameImageDetailsCollection (where: {
          AND:
          [
            {name_contains: $name},
            {
              AND: [
                {region_contains_some: $region},
                {year_contains_some: $year}
              ]
            }
          ]
        },
        limit: $limit) {
          items {
            name
            region
            year
            bioLong
            photoLogo {
              url
            }
            cta {
              url
            }
          }
        }
      },`,
      variables: {
        name: searchName,
        region: checkedFellowshipRegions,
        year: checkedFellowshipYears,
        limit: fellowshipSearchResultsLimit,
      },
    });
    return dispatch(
      setFellowsSearchResults(results.nameImageDetailsCollection.items)
    );
  };
};

export const setCatalogSearchFormatFilters = formats => {
  return (dispatch, getState) => {
    formats = formats
      .map(format => {
        if (format) {
          return format.split("es")[0];
        }
      })
      .filter(format => format);
    const { activeFormats } = getState().courseReducer;
    const updatedActiveFormats = Object.assign({}, activeFormats);

    Object.keys(updatedActiveFormats).forEach(format => {
      if (formats.includes(format)) {
        return (updatedActiveFormats[format] = true);
      }
      return (updatedActiveFormats[format] = false);
    });
    dispatch(setFormats(updatedActiveFormats));
    dispatch(setSearchCourses());
  };
};

export const setCatalogSearchTopicFilters = topics => {
  return (dispatch, getState) => {
    topics = topics.filter(topic => topic);
    const { activeTopics } = getState().courseReducer;
    const updatedActiveTopics = Object.assign({}, activeTopics);

    Object.keys(updatedActiveTopics).forEach(topic => {
      return topics.includes(topic)
        ? (updatedActiveTopics[topic] = true)
        : (updatedActiveTopics[topic] = false);
    });
    dispatch(setTopics(updatedActiveTopics));
    dispatch(setSearchCourses());
  };
};

export const setCatalogSearchAvailabilityFilters = availability => {
  return (dispatch) => {
    dispatch(setAvailabilityType(availability));
    dispatch(setSearchCourses());
  };
};

export const setCatalogSearchPaymentFilters = payment => {
  return (dispatch) => {
    dispatch(setPaymentType(payment));
    dispatch(setSearchCourses());
  };
};

export const sortCourses = sort => {
  return (dispatch) => {
    dispatch(setSortValue(sort));
    dispatch(setSearchCourses());
  };
};

export const setCoursesTimeframeFilter = timeframe => {
  return (dispatch) => {
    dispatch(setActiveTimeframe(timeframe));
    dispatch(setSearchCourses());
  };
};

export const setFiltersAreDisabled = disabled => ({
  type: SET_FILTERS_ARE_DISABLED,
  disabled,
});

export const resetSearchCourses = () => {
  return (dispatch, getState) => {
    const {
      activeFormats,
      activeTopics,
    } = getState().courseReducer;
    let updatedFormats = {},
      updatedTopics = {};
    Object.keys(activeFormats).forEach(
      format => (updatedFormats[format] = true)
    );
    Object.keys(activeTopics).forEach(topic => (updatedTopics[topic] = true));

    dispatch(setTopics(updatedTopics));
    dispatch(setFormats(updatedFormats));
    dispatch(setAvailabilityType("All"));
    dispatch(setPaymentType("All"));
    dispatch(setSearchCourses());
  };
};
export const setFooterHeight = footerHeight => ({
  type: SET_FOOTER_HEIGHT,
  footerHeight,
});

export const setPendingCourseEnrollmentNotification = (pendingCourseEnrollmentNotification ) => ({
  type: SET_PENDING_COURSE_ENROLLMENT_NOTIFICATION,
  pendingCourseEnrollmentNotification,
});
