import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const ThemeFive = ({ iconColorClassName, width, height, styles }) => {
  return (
    <svg
      width={width || "20px"}
      height={height || "20px"}
      className={iconColorClassName}
      css={css`
        ${styles || ""}
      `}
      viewBox="0 0 176.43 141.91"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M69.16,71C69.16,31.77,77.88,0,88.63,0S108.1,31.77,108.1,71s-8.71,71-19.47,71S69.16,110.14,69.16,71Z"
          />
          <path
            className="cls-1"
            d="M10.3,47.62a36.85,36.85,0,0,1,49.76,0,31.57,31.57,0,0,1,0,46.67,36.87,36.87,0,0,1-49.76,0A31.59,31.59,0,0,1,10.3,47.62Z"
          />
          <path
            className="cls-1"
            d="M116.37,47.62a36.85,36.85,0,0,1,49.76,0,31.57,31.57,0,0,1,0,46.67,36.87,36.87,0,0,1-49.76,0A31.57,31.57,0,0,1,116.37,47.62Z"
          />
        </g>
      </g>
    </svg>
  );
};

ThemeFive.propTypes = {
  iconColorClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.string,
};

export default ThemeFive;
