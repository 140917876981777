import React from "react";
import { css } from "@emotion/core";
import PropTypes from "prop-types";

const ThemeThree = ({ iconColorClassName, width, height, styles }) => {
  return (
    <svg
      width={width || "20px"}
      height={height || "20px"}
      className={iconColorClassName}
      css={css`
        ${styles || ""}
      `}
      viewBox="0 0 173.26 92.94"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M167.94,33.63a18.15,18.15,0,1,0,0,25.68A18.15,18.15,0,0,0,167.94,33.63Z"
          />
          <path
            className="cls-1"
            d="M121.84,13.61a46.47,46.47,0,1,0,0,65.72A46.47,46.47,0,0,0,121.84,13.61Z"
          />
          <path
            className="cls-1"
            d="M34.42,32.21a20.16,20.16,0,1,1-28.52,0A20.16,20.16,0,0,1,34.42,32.21Z"
          />
        </g>
      </g>
    </svg>
  );
};

ThemeThree.propTypes = {
  iconColorClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.string,
};

export default ThemeThree;
