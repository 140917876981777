import { graphql, useStaticQuery } from "gatsby";
const useSpanishHeaderFooterData = () => {
  return useStaticQuery(graphql`
    query {
      spanishFooter: contentfulFooter(node_locale: { eq: "es" }) {
        newsletterHeading
        newsLetterDescription {
          newsLetterDescription
        }
        column1Heading
        column1Links {
          linkText
          url
        }
        column2Heading
        column2Links {
          linkText
          url
        }
        column3Heading
        column3Links {
          linkText
          url
        }
        column4Heading
        column4Links {
          linkText
          url
        }
        facebook {
          url
        }
        linkedin {
          url
        }
        twitter {
          url
        }
        youtube {
          url
        }
        termsOfUse {
          linkText
          url
        }
        privacyPolicy {
          linkText
          url
        }
        emailErrorMessage
      }
      spanishHeader: contentfulGlobalHeader(node_locale: { eq: "es" }) {
        headerLinks {
          title
          url
          showSubLinks
          subLinks {
            linkText
            url
          }
        }
        myDashboardLinks {
          title
          url
          showSubLinks
          subLinks {
            linkText
            url
          }
        }
      }
      spanishNotifications: allContentfulNotifications(
        filter: { node_locale: { eq: "es" } }
      ) {
        nodes {
          title
          datePublished
          url
        }
      }
    }
  `);
};
export default useSpanishHeaderFooterData;
