import React, { useEffect, useRef } from "react";
import { theme } from "../../utils/global-styles/theme";
import { css, keyframes } from "@emotion/core";
import { Link } from "gatsby";
import DesktopArrow from "../Header/Dropdowns/DesktopArrow";

const dropdownContainerStyles = css`
  position: relative;
  display: inline-block;
`;

const dropdownButtonStyles = css`
  font-size: 17px;
  border: none;
  cursor: pointer;
  font-family: ${theme.fontFaces.paragraphs};
  svg {
    margin-left: 10px;
  }
`;

const dropdownContentItemStyles = css`
  padding: 10px;
  line-height: 20px;
  font-family: ${theme.fontFaces.paragraphs};
  color: ${theme.colors.white};
  font-weight: normal;
  &:hover {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.black};
  }
`;

const dropdownContentStyles = css`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${theme.colors.dkBlue};
  color: ${theme.colors.white};
  z-index: 1;
  overflow: hidden;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

const AnimatedDropdown = ({ title, items }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        const dropdownContent = dropdownRef.current.querySelector(".dropdown-content");
        dropdownContent.style.animationName = 'slideUp';
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    const dropdownContent = dropdownRef.current.querySelector(".dropdown-content");
    if (dropdownContent.style.animationName === "slideUp" || dropdownContent.style.animationName === "") {
      dropdownContent.style.display = "flex";
      dropdownContent.offsetHeight; 
      dropdownContent.style.animationName = 'slideDown';
    } else {
      dropdownContent.style.animationName = 'slideUp';
    }
  };

  return (
    <div ref={dropdownRef} css={dropdownContainerStyles}>
      <div css={dropdownButtonStyles} onClick={toggleDropdown}>
        {title}
        <DesktopArrow />
      </div>
      <div className="dropdown-content" css={dropdownContentStyles}>
        {items.map((item, index) => (
          <Link
            className="border-bottom-light"
            to={item.url}
            css={dropdownContentItemStyles}
            key={index}
          >
            {item.linkText}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AnimatedDropdown;
