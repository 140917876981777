import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import CloseIcon from "../../shared/CloseIcon";
import { theme } from "../../../utils/global-styles/theme";

const NotificationBar = ({
  displayText,
  cta,
  setIsNotificationBarShowing,
  isNotificationBarShowing,
}) => {
  const Container = styled.div`
      padding: 1em;
      color: ${theme.colors.white};
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: fixed;
      z-index: ${isNotificationBarShowing ? "8" : "0"};
      width: 100%;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        flex-direction: column;
        text-align: center;
      }
    `,
    TextContainer = styled.div`
      display: flex;
      align-items: center;
      gap: 1em;
      flex-flow: wrap;
      text-align: center;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        flex-direction: column;
        text-align: center;
        gap: 0;
      }
      @media only screen and (min-width: ${theme.breakpoints
          .tabletUp}) and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
        p {
          max-width: 70%;
        }
      }
      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) and (max-width: ${theme.breakpoints.desktopUp}) {
        p {
          max-width: 80%;
        }
      }
      @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
        p {
          max-width: 85%;
        }
      }
    `,
    Cta = styled.a`
      color: ${theme.colors.white};
      display: inline;
      padding: 10px;
      border: 2px solid ${theme.colors.white};
      cursor: pointer;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        display: block;
        margin: 1em 0;
        padding: 5px;
      }
    `,
    dismissNotification = () => {
      localStorage.setItem(
        "notification",
        JSON.stringify({
          title: displayText,
          showNotification: false,
        })
      );
    };
  return (
    <Container className="primary-bg">
      <TextContainer>
        <p>{displayText}</p>
        <a className="white white-button" href={cta.url} target="_blank">
          {cta.linkText}
        </a>
      </TextContainer>
      <CloseIcon
        styles={`position: absolute;
           right: 2em;
           @media only screen and (max-width: ${theme.breakpoints.mobile}) {
             position: inherit;
           }
          `}
        onClick={() => {
          dismissNotification();
          setIsNotificationBarShowing();
        }}
      />
    </Container>
  );
};

NotificationBar.propTypes = {
  displayText: PropTypes.string,
  cta: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  dismissNotification: PropTypes.func,
};

export default NotificationBar;
