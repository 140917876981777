import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import { css } from "@emotion/core";

const DownArrowIcon = ({
  height,
  width,
  classNames,
  fillColor,
  rotateValue,
}) => (
  <svg
    width={width || "18px"}
    height={height || "11px"}
    viewBox="0 0 18 11"
    className={classNames}
    css={css`
      transform: rotate(${rotateValue}deg);
      transition: all 0.3s ease-out;
    `}
  >
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Fellowship-Lander-1.0-Mobile"
        transform="translate(-334.000000, -312.000000)"
        fill={fillColor || theme.colors.primary}
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(14.000000, 296.000000)">
          <path
            d="M324.444191,13.4472362 C323.851936,14.0409891 323.851936,15.0001283 324.444191,15.5938812 L330.336371,21.5009608 L324.444191,27.4080404 C323.851936,28.0017933 323.851936,28.9609325 324.444191,29.5546854 C325.036446,30.1484382 325.993166,30.1484382 326.585421,29.5546854 L333.555809,22.566671 C334.148064,21.9729182 334.148064,21.013779 333.555809,20.4200261 L326.585421,13.4320118 C326.008352,12.8534834 325.036446,12.8534834 324.444191,13.4472362 Z"
            id="Path-Copy-3"
            transform="translate(329.000000, 21.500000) scale(1, -1) rotate(-90.000000) translate(-329.000000, -21.500000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

DownArrowIcon.propTypes = {
  classNames: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  classNames: PropTypes.string,
  fillColor: PropTypes.string,
  rotateValue: PropTypes.string,
};

export default DownArrowIcon;
