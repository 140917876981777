import { css } from "@emotion/core";
import { theme } from "./theme";

const { fontFaces, mixIns, colors, breakpoints } = theme;

const { primary, secondary, tertiary } = colors;

const typography = css`
.large-logo path:first-of-type {
  fill: ${tertiary} !important;
}

.large-logo path:nth-of-type(2) {
  fill: ${primary} !important;
}

.large-logo path:last-of-type {
  fill: ${secondary} !important;
}
  body,
  p,
  ol,
  ul,
  li {
    font-family: ${fontFaces.paragraphs};
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    letter-spacing: -0.37px;
  }

  a,
  .smTitleWhite,
  .ccTitle,
  label {
    font-family: ${fontFaces.headers};
  }

  .smTitleWhite {
    font-weight: 700;
  }

  .ccTitle {
    font-weight: 500;
  }

  h1 {
    font-size: 66px;
    line-height: 73px;
    font-family: ${theme.fontFaces.headers};
  }
  h2 {
    font-size: 46px;
    line-height: 53px;
    font-family: ${theme.fontFaces.headers};
  }
  h3 {
    font-size: 46px;
    line-height: 53px;
    font-family: ${theme.fontFaces.paragraphs};
  }
  h4 {
    font-size: 33px;
    line-height: 40px;
    font-family: ${theme.fontFaces.headers};
  }
  h5 {
    font-size: 33px;
    line-height: 40px;
    font-family: ${theme.fontFaces.paragraphs};
  }
  h6 {
    font-size: 22px;
    line-height: 29px;
    font-family: ${theme.fontFaces.headers};
    &.allCaps {
      line-height: 20px;
    }
  }
  .h7 {
    font-family: ${fontFaces.headers};
    font-weight: 500;
    color: ${colors.primary};
    font-size: 15px;
    line-height: 18px;
  }
  .h8 {
    font-family: ${fontFaces.headers};
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.2px;
  }
  p {
    font-size: 20px;
    line-height: 29px;
    font-family: ${theme.fontFaces.paragraphs};
  }
  caption {
    font-size: 22px;
    line-height: 29px;
    font-family: ${theme.fontFaces.paragraphs};
  }
  .hero-heading {  
    font-size: 93px;
    line-height: 73px;
    font-family: ${theme.fontFaces.headers};
  }

  .p1 {
    font-family: ${fontFaces.headers};
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.62px;
  }
  .p2 {
    font-size: 22px;
    line-height: 43px;
    letter-spacing: -0.57px;
    font-family: ${fontFaces.headers};
    font-weight: bold;
    &.white {
      text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .p3 {
    font-family: ${fontFaces.headers};
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: -0.06px;
  }
  .p4 {
    font-family: ${fontFaces.headers};
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.39px;
  }
  .p5 {
    font-family: ${fontFaces.headers};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.3px;
  }
  .p6 {
    font-family: ${fontFaces.paragraphs};
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.43px;
  }

  .aaCaption {
    font-family: ${fontFaces.headers};
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.31px;
  }

  .ccTitle {
    letter-spacing: -0.2px;
    font-size: 0.8125rem;
    line-height: 1rem;
  }

  .allCaps {
    ${mixIns.allCaps}
  }

  .bold {
    font-weight: bold;
  }

  .featured-foundry-member-card-markdown {
    h2 {
      font-size: 20px;
      line-height: 1.3em;
      font-weight: normal;
    }
    p {
      display: none;
    }
  }

  .foundry-member-modal-card-markdown {
    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: 0em;
      margin-bottom: 5px;
  
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
       
      }
    }
  
  }

  .foundry-member-grid-card-markdown {
    h2 {
      font-size: 15px !important;
      font-weight: bold;
      font-family: ${theme.fontFaces.paragraphs};
      line-height: 19px !important;
      letter-spacing: 0em;
      margin-bottom: 5px;
    }
    p {
      font-weight: medium;
    }
  }
  .codedH1 { 
    font-size: 93px;
    line-height: 100px;
   }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 60px;
      line-height: 66px;
    }
    h2 {
      font-size: 46px;
      line-height: 53px;
    }
    h3 {
      font-size: 46px;
      line-height: 53px;
    }
    h4 {
      font-size: 33px;
      line-height: 40px;
    }
    h5 {
      font-size: 33px;
      line-height: 40px;
    }
    h6 {
      font-size: 22px;
      line-height: 29px;
      &.allCaps {
        line-height: 24px;
      }
    }


    a {
      color: ${colors.main};
    }

    .h7 {
      font-size: 20px;
      line-height: 24px;
    }
    .h8 {
      letter-spacing: -0.25px
      font-size: 16px;
      line-height: 19px;
    }
    .smTitleWhite {
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      color: ${colors.white};
    }
    .ccTitle {
      font-size: 1rem;
      line-height: 1.1875rem;
      letter-spacing: -0.25px;
    }
    .p1 {
      letter-spacing: -1.38px;
      font-size: 53px;
      line-height: 74px;
    }
    .p2 {
      letter-spacing: -0.9px;
      font-size: 35px;
      line-height: 43px;
    }
    .p3 {
      letter-spacing: -0.09px;
      font-size: 30px;
      line-height: 37px;
    }
    .p4 {
      letter-spacing: -0.45px;
      font-size: 21px;
      line-height: 32px;
    }
    .p5 {
      letter-spacing: -0.37px;
      font-size: 17px;
      line-height: 24px;
    }
    .p6 {
      letter-spacing: -0.57px;
      font-size: 20px;
      line-height: 31px;
    }

    .aaCaption {
      letter-spacing: 0.37px;
      font-size: 13px;
      line-height: 16px;
    }
    p {
      font-size: 21px;
      line-height: 28px;
    }
  
    caption {
      font-size: 18px;
      line-height: 24px;
    }
  
    .hero-heading {  
      font-size: 73px;
      line-height: 80px;
    }

    .codedH1 { 
      font-size: 73px;
      line-height: 80px;
      display: block;
      width: 100%;
      word-wrap: break-word;
     }
  }
`;

export default typography;
